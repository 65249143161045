import React from 'react'
import ProformaInvoiceForm from '../ProformaInvoiceForm'
import requests from '../ProformaInvoices.requests'

export default function ProformaInvoiceCreate({
    onSuccess,
}: Readonly<{
    onSuccess: (a: any) => void
}>) {
    return (
        <ProformaInvoiceForm
            onSuccess={onSuccess}
            requestParam={requests.create()}
        />
    )
}
