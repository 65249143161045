import React, {
    useMemo,
} from 'react'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import ProformaInvoiceCreate from './ProformaInvoiceCreate'
import ProformaInvoiceUpdate from './ProformaInvoiceUpdate'
import ProformaInvoiceOverview from './ProformaInvoiceOverview'
import getTableConfig from './ProformaInvoices.config'

const tableControls: TableControlTypes[] = [
    TableControlTypes.Search,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

export default function ProformaInvoices() {
    const createPermissions = useHasPermission(['proforma_rule_create'])
    const updatePermissions = useHasPermission(['proforma_rule_update'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: CommonRoutes.OVERVIEW,
                Component: ProformaInvoiceOverview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: CommonRoutes.DATA_UPDATE,
                Component: ProformaInvoiceUpdate,
            },
            permission: updatePermissions,
        },
    ])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: createPermissions ? ProformaInvoiceCreate : undefined,
            }}
            name={tableConfig.name}
            sidebarTabs={tabs}
            defaultTab={CommonRoutes.OVERVIEW}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
            />
        </SkyNetDomain>
    )
}
