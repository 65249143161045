import React, {
    useCallback,
} from 'react'

import useEnumValues from 'app/hooks/useEnumValues'
import MultipleSelect from './MultipleSelect'

const defaultProps = {
    title: '',
    name: '',
    value: [],
    disabled: false,
    required: false,
}

export default function MultipleSelectContainer({
    onChange,
    allowedValues,
    title,
    name,
    value,
    disabled,
    required,
}: Readonly<{
    onChange: (...args: any) => void,
    allowedValues: Record<string, string>,
    title?: string,
    name?: string,
    value?: string[],
    disabled?: boolean,
    required?: boolean,
}>) {
    const options = useEnumValues()(allowedValues)
    const handleChange = useCallback(({
        target,
    }) => {
        onChange(target?.value, {
            target: {
                name,
                value: target?.value,
            },
        })
    }, [
        name,
        onChange,
    ])

    return (
        <MultipleSelect
            options={options}
            title={title}
            value={value}
            name={name}
            disabled={disabled}
            required={required}
            onChange={handleChange}
        />
    )
}

MultipleSelectContainer.defaultProps = defaultProps
