import {
    SkyNetTableConfig, SkyNetTableField,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    OrderSubType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    ProductType,
} from 'app/types/enums'

import {
    PoolingFields,
} from '../pooling.types'

export const deliveryAddInfoFields: SkyNetTableField<PoolingFields>[] = [
    {
        name: 'orderNumber',
        width: '100px',
    },
    {
        name: 'from',
        filterField: 'from',
        filterType: FilterType.Array,
        width: '80px',
    },
    {
        name: 'to',
        filterField: 'to',
        filterType: FilterType.Array,
        width: '80px',
    },
    {
        name: 'orderSubType',
        filterField: 'orderSubType',
        filterType: FilterType.Array,
        componentName: 'EnumValue',
        width: '150px',
        allowedValuesEnum: OrderSubType,
    },
    {
        name: 'forwarderImport',
        labelKey: 'Order.forwarderImport',
        width: '200px',
        sorting: false,
    },
    {
        name: 'forwarderExport',
        labelKey: 'Order.forwarderExport',
        width: '200px',
        sorting: false,
    },
    {
        name: 'originLocationLocationName',
        filterField: 'originLocationLocationName',
        filterType: FilterType.Array,
        width: '200px',
    },
    {
        name: 'firstStepDeliveryLocationLocationName',
        width: '200px',
        sorting: false,
    },
    {
        name: 'destinationLocationLocationName',
        filterField: 'destinationLocationLocationName',
        filterType: FilterType.Array,
        width: '250px',
    },
    {
        name: 'mainMeansOfTransport',
        componentName: 'TransportMode',
        headerProps: {
            children: 'Mode',
        },
        filterField: 'mainMeansOfTransport',
        filterType: FilterType.Array,
        width: '150px',
    },
    {
        name: 'requiredContainers',
        componentName: 'RequiredContainersList',
        allowedValuesEnum: ProductType,
        filterField: 'requiredContainersContainerModel',
        filterType: FilterType.Array,
        labelKey: 'OrderPoolingInternalTransportsView.requiredContainers',
        width: '200px',
        sorting: false,
    },
    {
        name: 'requestedDeliveryLastStep',
        componentName: 'RequestedDeliveryAddInfo',
        labelKey: 'OrderStep.requestedDelivery',
        sorting: false,
    },
]

export default (): SkyNetTableConfig<PoolingFields> => {
    return {
        name: 'Pooling.AddInfo',
        tabs: [{
            name: 'Delivery: Add Info',
            url: CommonRoutes.ALL,
            fields: deliveryAddInfoFields,
        }],
    }
}
