import {
    LaneGroupTemperatureRange,
} from 'app/types/enums'
import {
    IncoTerm,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'

export enum StatusLongTermLease {
    NOT_STARTED = 'NOT_STARTED',
    CONFIRMED = 'CONFIRMED',
    BOOKED = 'BOOKED',
    RENTAL = 'RENTAL',
    CLOSED = 'CLOSED',
}

export type LongTermLeaseTableType = {
    contractNumber: string,
    customerExecutionCompanyName: string,
    customerExecutionContactsContactName: string[],
    pricingAccountCompanyName: string,
    requiredContainers: RequiredContainersCustomer[],
    leaseStartTimestamp: string,
    status: StatusLongTermLease,
}

export type LongTermLeaseType = {
    contractNumber: string,
    customerExecution: {id: number},
    customerExecutionContacts: {id: number}[],
    pricingAccount: {id: number},
    customerComment: string,
    customerReference: string,
    receivedOn: string,
    enteredOn: string,
    tempRange: LaneGroupTemperatureRange,
    incoTerm: IncoTerm,
    leaseStartTimestamp: string,
    leaseEndTimestamp: string,
    commentForBillingOps: string,
    requiredContainers: RequiredContainersCustomer
}
