import {
    tss,
} from 'app/tss'

export default tss.create(({
    theme,
}) => {
    const general = 'general'
    const handover = 'handover'
    const collection = 'collection'
    const consignee = 'consignee'
    const customerExecution = 'customerExecution'

    const gridTemplateAreas = `
                "${general} ${handover}"
                "${consignee} ${handover}"
                "${customerExecution} ${collection}"
                `

    return {
        formContainer: {
            padding: theme.spacing(1),
            cursor: 'auto',
            background: theme.palette.secondary[50],
        },
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas,
            gridTemplateColumns: 'repeat(2, 2fr)',
        },
    }
})
