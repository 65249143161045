import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    LongTermLeaseType,
} from '../../../longTermLease.types'

const fields: DTOCardFormField<keyof LongTermLeaseType>[] = [{
    title: 'General',
    className: 'general',
    id: 'general',
    fields: [
        {
            id: 'generalColumn1',
            fields: [
                {
                    name: 'contractNumber',
                    componentName: 'InputSingleline',
                    disabled: true,
                    labelKey: 'LongTermLeaseForm.contractNumber',
                },
                {
                    name: 'customerExecution',
                    componentName: 'LocationExpandedViewObjectSelector',
                    required: true,
                    labelKey: 'AccountLane.customerExecution',
                },
                {
                    name: 'customerExecutionContacts',
                    componentName: 'MultipleContactObjectSelector',
                    required: true,
                    labelKey: 'Order.customerContacts',
                },
            ],
        },
        {
            id: 'generalColumn2',
            fields: [
                {
                    name: 'pricingAccount',
                    componentName: 'AccountSelector',
                    required: true,
                    labelKey: 'Prebooking.pricingAccount',
                },
                {
                    name: 'customerComment',
                    componentName: 'LongText',
                    labelKey: 'Order.customerComment',
                },
            ],
        },
    ],
}]

export default fields
