import React, {
    useCallback,
} from 'react'
import clsx from 'clsx'
import {
    Select as MaterialSelect,
    Input,
    FormControl,
    MenuItem, Checkbox,
    ListItemText,
} from '@mui/material'

import Label from 'app/shared-components/Label'

import useStyles from './MultipleSelect.styles'

const defaultProps = {
    name: 'multiple-select',
    title: '',
    value: [],
    disabled: false,
    required: false,
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

export default function MultipleSelect({
    name,
    title,
    value,
    onChange,
    options,
    disabled,
    required,
}: Readonly<{
    name?: string,
    title?: string,
    value?: string[],
    onChange: (...args: any[]) => void,
    options: Record<string, string>,
    disabled?: boolean,
    required?: boolean,
}>) {
    const {
        classes,
    } = useStyles()

    const renderValue = useCallback((selected: string[]) => {
        return Object.entries(options).filter((option) => {
            return selected.includes(option[0])
        }).map((label) => {
            return label[1]
        }).join(', ')
    }, [options])

    return (
        <FormControl
            className={clsx(
                classes.formControl,
            )}
            variant="standard"
        >
            {title && (
                <Label
                    title={title}
                />
            )}
            <div className={clsx(
                {
                    [classes.isRequired]: required,
                },
            )}
            >
                <div className={classes.requiredMark} />
                <MaterialSelect
                    labelId="mutiple-checkbox-label"
                    id="multiple-checkbox"
                    fullWidth
                    multiple
                    value={value}
                    className={
                        clsx(
                            {
                                [classes.isRequiredBackground]: required,
                            },
                            classes.multipleSelect,
                        )
                    }
                    onChange={onChange}
                    disabled={disabled}
                    input={<Input />}
                    renderValue={renderValue}
                    MenuProps={MenuProps}
                    data-testid={`multiple-select-${name}`}
                    classes={{
                        select: classes.select,
                    }}
                >
                    {Object.entries(options).map((option) => {
                        const isChecked = value.includes(option[0])

                        return (
                            <MenuItem
                                key={`menu-item-${option[1]}`}
                                value={option[0]}
                                className={classes.menuItem}
                            >
                                <Checkbox
                                    value={isChecked}
                                    checked={isChecked}
                                />
                                <ListItemText primary={option[1]} />
                            </MenuItem>
                        )
                    })}
                </MaterialSelect>
            </div>
        </FormControl>
    )
}

MultipleSelect.defaultProps = defaultProps
