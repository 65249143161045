import {
    useMemo,
} from 'react'
import {
    App, CommonRoutes, useSkyNetNavigate,
} from 'app/SkyNetRoutes'
import {
    createRoutesConfig,
} from 'app/SkyNetRoutes/skyNetRoutes.utils'
import useCreateSkyNetDomainRoutes, {
    SkyNetDomainSubRoutes,
} from 'app/shared-components/SkyNetDomain/useCreateSkyNetDomainRoutes'
import {
    SkyNetRoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import {
    createChildEditRoutes,
} from 'app/shared-components/SkyNetDomain'

import {
    OrderManagementDomains,
} from '../OrderManagement.types'
import {
    LanesTabs,
} from '../Lanes/lanes.types'
import {
    CustomerTransportTabs,
} from '../Orders/CustomerTransport/CustomerTransport.types'
import {
    InternalTransportDomainsRoutes,
    InternalTransportTabs,
} from '../Orders/InternalTransport/InternalTransport.types'

export default function useOrderManagementRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const createSkyNetDomainRoutes = useCreateSkyNetDomainRoutes()

    return useMemo(() => {
        const SkyNetDomainRoutes = createSkyNetDomainRoutes()
        const config: SkyNetRoutesConfig = {
            path: [App.ORDER_MANAGEMENT],
            DailyOps: {
                route: OrderManagementDomains.DailyOps,
            },
            Pooling: {
                route: OrderManagementDomains.Pooling,
            },
            OpenBookings: {
                route: OrderManagementDomains.OpenBookings,
            },
            Lanes: {
                route: OrderManagementDomains.Lanes,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                LaneImplementation: {
                    route: LanesTabs.LaneImplementation,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Notifications: {
                    route: LanesTabs.Notifications,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            CustomerOrders: {
                route: OrderManagementDomains.CustomerOrders,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Notifications: {
                    route: CustomerTransportTabs.Notifications,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                OrderChanges: {
                    route: CustomerTransportTabs.OrderChanges,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ContainerSelection: {
                    route: CustomerTransportTabs.ContainerSelection,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                OrderSteps: {
                    route: CustomerTransportTabs.OrderSteps,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                BillingInformation: {
                    route: CustomerTransportTabs.BillingInformation,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Invoices: {
                    route: CustomerTransportTabs.Invoices,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                SharedPositioning: {
                    route: CustomerTransportTabs.SharedPositioning,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                OrderConfirmation: {
                    route: CustomerTransportTabs.OrderConfirmation,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                CreateAirlineLease: {
                    route: OrderManagementDomains.CreateAirlineLease,
                },
                CreateDirectLease: {
                    route: OrderManagementDomains.CreateDirectLease,
                },
            },
            InternalOrders: {
                route: OrderManagementDomains.InternalOrders,
                ...createChildEditRoutes(
                    createSkyNetDomainRoutes({
                        ...InternalTransportDomainsRoutes,
                    }),
                )({
                    Overview: CommonRoutes.OVERVIEW,
                    Notifications: InternalTransportTabs.Notifications,
                    ContainerSelection: InternalTransportTabs.ContainerSelection,
                    OrderSteps: InternalTransportTabs.OrderSteps,
                    ChangeHistory: CommonRoutes.CHANGE_HISTORY,
                }),
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Notifications: {
                    route: InternalTransportTabs.Notifications,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ContainerSelection: {
                    route: InternalTransportTabs.ContainerSelection,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                OrderSteps: {
                    route: InternalTransportTabs.OrderSteps,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Create: {
                    route: SkyNetDomainSubRoutes.CREATE,
                },
            },
            LongTermLease: {
                route: OrderManagementDomains.LongTermLease,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Create: {
                    route: SkyNetDomainSubRoutes.CREATE,
                },
            },
            PreBookings: {
                route: OrderManagementDomains.PreBookings,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            ProformaInvoices: {
                route: OrderManagementDomains.ProformaInvoices,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        createSkyNetDomainRoutes,
        skyNetNavigate,
    ])
}
