// eslint-disable-next-line import/prefer-default-export
export enum InternalTransportTabs {
    Notifications = 'notifications',
    ContainerSelection = 'container-selection',
    OrderSteps = 'order-steps',
}

export enum InternalTransportDomainsRoutes {
    PickupAddInfo = 'pickup-add-info',
    PickupAddPickup = 'pickup-add-pickup',
    PickupDeliveryConfirmation = 'pickup-delivery-confirmation',
    TransportDeparture = 'transport-departure',
    TransportArrival = 'transport-arrival',
    DeliveryAddInfo = 'delivery-add-info',
    DeliveryAddPickup = 'delivery-add-pickup',
    DeliveryConfirmation = 'delivery-confirmation',
}
