import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react'
import {
    Country,
} from 'app/Apps/Masterdata/Countries/Countries.types'
import requests from 'app/Apps/Masterdata/Countries/Countries.request'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import useGetCountriesById from 'app/Apps/Masterdata/Countries/hooks/useGetCountriesById'
import ChipSelect from 'app/shared-components/ChipSelect'

const defaultProps = {
    value: [],
    title: 'Countries',
    disabled: false,
    required: false,
    className: '',
    disableCache: false,
}

export default function MultipleCountriesSelector({
    value,
    onChange,
    title,
    disabled,
    required,
    className,
    disableCache,
}: Readonly<{
    value?: Partial<Country>[],
    onChange: (newValue: Partial<Country>[]) => void,
    title?: string,
    className?: string,
    disabled?: boolean,
    required?: boolean,
    disableCache?: boolean,
}>) {
    const [
        state,
        setState,
    ] = useState<Partial<Country>[]>(null)

    const loadOptions = useDelayedLoadOptions(requests.selector)

    // we need to do the request only once
    const countriesId = useMemo(() => {
        return state === null && value.map(({
            id,
        }) => {
            return id
        })
    }, [
        value,
        state,
    ])

    const {
        data: countries,
        isDataReady,
    } = useGetCountriesById(countriesId)

    useEffect(() => {
        if (isDataReady) {
            setState(countries.length ? requests.selector.convertion(countries) : [])
        }
    }, [
        countries,
        isDataReady,
    ])

    const handleChange = useCallback((items: Partial<Country>[]) => {
        setState(() => {
            onChange(items)
            return items
        })
    }, [onChange])

    return (
        <div className={className}>
            <ChipSelect
                title={title}
                value={state ?? []}
                onChange={handleChange}
                loadOptions={loadOptions}
                showTitleOptions
                disabled={disabled}
                required={required}
                cacheOptions={!disableCache}
                menuShouldBlockScroll
                menuPortalTarget={document.body}
            />
        </div>
    )
}

MultipleCountriesSelector.defaultProps = defaultProps
