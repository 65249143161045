/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'

import MultipleSelect from '../MultipleSelect'

const propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.array),
    value: PropTypes.arrayOf(PropTypes.string),
}

const defaultProps = {
    value: [],
    options: [],
}

const MultipleSelectUnselectableContainer = (props) => {
    const {
        onChange,
        options,
    } = props

    const optionsMap = useMemo(() => {
        return new Map(options)
    }, [options])

    const handleChange = useCallback((value) => {
        onChange(value.map((item) => {
            return [
                item,
                optionsMap.get(item),
            ]
        }))
    }, [
        onChange,
        optionsMap,
    ])

    return (
        <MultipleSelect
            {...props}
            onChange={handleChange}
        />
    )
}

MultipleSelectUnselectableContainer.propTypes = propTypes
MultipleSelectUnselectableContainer.defaultProps = defaultProps

export default MultipleSelectUnselectableContainer
