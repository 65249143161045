import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    Containers,
} from 'app/Apps/ContainerManagement/Containers/containers.types'
import {
    TemperatureRangePreconditioned,
} from 'app/types/enums'
import {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'

export default ({
    availableContainersLinkConfig,
}: {
    availableContainersLinkConfig?: {
        path: string[],
        params: Record<string, string>,
    }
}): SkyNetTableConfig<keyof Containers | 'jypId' | 'locationLocationName' | 'currentOrderOrderNumber'> => {
    return {
        name: 'AvailableContainersTable',
        fields: [
            {
                name: 'serialNumber',
                width: '160px',
                componentName: 'SkyNetTableLink',
                linkConfig: availableContainersLinkConfig,
                type: TypeLink.EXTERNAL,
                size: SizeLink.S,
            },
            {
                name: 'jypId',
                labelKey: 'Container.jypNumber',
                width: '150px',
            },
            {
                name: 'containerStatusEnriched',
                componentName: 'ContainerStatusEnriched',
                filterField: 'containerStatusEnriched',
                filterType: FilterType.Array,
                width: '220px',
            },
            {
                name: 'tempRange',
                componentName: 'EnumValue',
                allowedValuesEnum: TemperatureRangePreconditioned,
                filterField: 'tempRange',
                filterType: FilterType.Array,
                width: '150px',
            },
            {
                name: 'lastMeasuredTempInternal',
                labelKey: 'AvailableContainersView.lastMeasuredTempInternal',
                width: '220px',
                componentName: 'ContainerTemp',
            },
            {
                name: 'lastMeasuredTempAmbient',
                labelKey: 'AvailableContainersView.lastMeasuredTempAmbient',
                width: '220px',
                componentName: 'ContainerTemp',
            },
            {
                name: 'containerTypeContainerModel',
                filterField: 'containerTypeContainerModel',
                filterType: FilterType.Array,
                width: '320px',
            },
            {
                name: 'locationLocationName',
                width: '380px',
            },
            {
                name: 'currentOrderOrderNumber',
                width: '150px',
            },
        ],
    }
}
