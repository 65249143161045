import React, {
    useMemo,
} from 'react'

import SkyNetLink, {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'
import {
    selectConfigByFieldName,
} from 'app/store/Configs'
import {
    useAppSelector,
} from 'app/store/hooks'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'

import useStyles from './SkyNetTableLink.styles'

export default function SkyNetTableLink({
    value,
    mapCellProps,
    configName,
    rowProps,
}: {
    value: string,
    configName: string,
    mapCellProps: string,
    rowProps: Record<string, any>,
}) {
    const {
        classes,
    } = useStyles()
    const {
        state: linkState,
        size = SizeLink.M,
        type = TypeLink.INTERNAL,
        notificationIconProps,
        className,
        linkConfig,
    } = useAppSelector((state) => {
        return selectConfigByFieldName(state, configName, mapCellProps)
    })

    const link = useMemo(() => {
        return linkConfig?.path ? generateSkyNetLink({
            domainPath: linkConfig.path,
            params: Object.entries(linkConfig?.params || {}).reduce((acc, [
                key,
                propName,
            ]: [string, string
]) => {
                return {
                    ...acc,
                    [key]: rowProps?.[propName],
                }
            }, {}),
        }) : undefined
    }, [
        linkConfig?.params,
        linkConfig?.path,
        rowProps,
    ])

    return (
        <SkyNetLink
            size={size}
            type={type}
            state={linkState}
            notificationIconProps={notificationIconProps}
            className={className}
            customClasses={{
                wrapper: classes.wrapper,
            }}
            value={value}
            link={link}
        />
    )
}
