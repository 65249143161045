import {
    FieldInCard,
} from 'app/types/form.types'
import {
    ProductType,
} from 'app/types/enums'
import {
    ModificationReason,
    ModificationRequiredContainersFields,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getFields = ({
    allowedProductTypes,
    getAllowedValues,
    modificationReason,
}: {
    allowedProductTypes: ProductType[],
    getAllowedValues: (enumType: Record<string, string>) => Record < string, string >,
    modificationReason: ModificationReason,
}): FieldInCard<ModificationRequiredContainersFields>[] => {
    return [
        {
            id: 'column1',
            fields: [
                {
                    name: 'modificationReason',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(ModificationReason),
                    useDropdown: true,
                    required: true,
                    labelKey: 'OrderByNumberView.modificationReason',
                },
                {
                    name: 'modificationRequestedOn',
                    componentName: 'DateTimeZonedSelect',
                    required: true,
                    labelKey: 'OrderByNumberView.modificationRequestedOn',
                },
                {
                    name: 'modificationComment',
                    componentName: 'LongText',
                    multiline: true,
                    required: modificationReason === ModificationReason.CUSTOMER,
                    labelKey: 'OrderByNumberView.modificationComment',
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'requiredContainers',
                    componentName: 'RequiredContainerByProductTypeList',
                    allowedProductTypes,
                    labelKey: 'OrderByNumberView.requiredContainers',
                },
                {
                    name: 'requiredCargoComment',
                    componentName: 'LongText',
                    multiline: true,
                    labelKey: 'OrderByNumberView.requiredCargoComment',
                },
            ],
        },
    ]
}

export default getFields
