import React, {
    useState, useMemo, useCallback,
} from 'react'
import clsx from 'clsx'

import {
    FormFields,
} from 'app/types/form.types'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'

import DomainObjectForm from '../DomainObjectForm'

import useStyles from './DomainObjectFormWithControls.styles'

type Props = {
    value?: Record<string, any>,
    fields: FormFields,
    edited: boolean,
    name: string,
    reset: () => void,
    onChange: (data: Record<string, any>) => void,
    update: () => void,
    create: () => void,
    remove: () => void,
    disabled?: boolean,
    customButtons?: JSX.Element[],
    cancelEnabled?: boolean,
    exists?: boolean,
    canDelete?: boolean,
    canCancel?: boolean,
    createEnabled?: boolean,
    noCard?: boolean,
    customLabelSave?: string,
    classNames?: {
        gridWrapper?: string,
        gridAreaTemplate?: string,
    },
    className?: string,
    'data-testid'?: string,
    wrapper?: string,
    isLoading?: boolean,
    disableOnError?: boolean,
    isValid?: boolean,
    getCustomButtons?: (...a: any[]) => JSX.Element[]
}

const defaultProps = {
    disabled: false,
    exists: false,
    classNames: {},
    className: null,
    canDelete: false,
    canCancel: true,
    cancelEnabled: false,
    createEnabled: true,
    getCustomButtons: undefined,
    customButtons: [],
    'data-testid': 'domainObjectFormWithControls',
    customLabelSave: undefined,
    value: undefined,
    noCard: false,
    isLoading: false,
    wrapper: undefined,
    disableOnError: false,
    isValid: true,
}

const DomainObjectFormWithControls = (props: Props) => {
    const [
        errors,
        setErrors,
    ] = useState({})
    const {
        classes,
    } = useStyles()

    const {
        disabled,
        reset,
        exists,
        className,
        canDelete,
        canCancel,
        classNames,
        create,
        update,
        edited,
        remove,
        cancelEnabled,
        createEnabled,
        getCustomButtons: getCustomButtonsFunc,
        customButtons,
        'data-testid': dataTestid,
        customLabelSave,
        name,
        value,
        fields,
        wrapper,
        onChange,
        noCard,
        isLoading,
        disableOnError,
        isValid,
    } = props

    const isDisabledOnError = useMemo(() => {
        if (disableOnError) {
            return Boolean(Object.keys(errors).length) || !isValid
        }
        return false
    }, [
        disableOnError,
        isValid,
        errors,
    ])

    const setFormErrors = useCallback(([
        key,
        error,
    ]) => {
        if (error?.length > 0) {
            setErrors((prev) => {
                return {
                    ...prev,
                    [key]: error,
                }
            })
            return
        }

        setErrors((prev) => {
            const next = {
                ...prev,
            }

            delete next[key]
            return next
        })
    }, [setErrors])

    const getCustomButtons = useCallback(() => {
        if (!getCustomButtonsFunc) return customButtons || []

        return getCustomButtonsFunc({
            value,
            isValid,
            isDisabledOnError,
            disableOnError,
            edited,
        })
    }, [
        value,
        customButtons,
        getCustomButtonsFunc,
        isValid,
        isDisabledOnError,
        disableOnError,
        edited,
    ])

    return (
        <div
            data-testid={dataTestid}
            className={clsx(classes.root, className)}
        >
            <DomainObjectForm
                name={name}
                value={value}
                fields={fields}
                classNames={classNames}
                wrapper={wrapper}
                onChange={onChange}
                disabled={disabled}
                exists={exists}
                noCard={noCard}
                className={clsx(
                    classNames.gridWrapper,
                    classNames.gridAreaTemplate,
                )}
                setErrors={setFormErrors}
            />
            {!disabled && (
                <ButtonContainer>
                    {!exists && (
                        <Button
                            disabled={!(createEnabled && edited) || isDisabledOnError}
                            label="Create"
                            onClick={create}
                            saving={isLoading}
                        />
                    )}
                    {exists && (
                        <Button
                            disabled={!(createEnabled && edited) || isDisabledOnError}
                            label={customLabelSave || 'Save'}
                            onClick={update}
                            saving={isLoading}
                        />
                    )}
                    {getCustomButtons().map((customButton) => {
                        return (
                            <div
                                key={`custom-button-${customButton.props.name}`}
                            >
                                {customButton}
                            </div>
                        )
                    })}
                    {(exists && canDelete) && (
                        <Button
                            secondary
                            label="Delete"
                            onClick={remove}
                        />
                    )}
                    {canCancel && (
                        <Button
                            disabled={!(cancelEnabled || edited) || isLoading}
                            secondary
                            label="Cancel"
                            onClick={reset}
                        />
                    )}
                </ButtonContainer>
            )}
        </div>
    )
}

DomainObjectFormWithControls.defaultProps = defaultProps

export default DomainObjectFormWithControls
