import {
    TransportModeRoad,
    OrderStepFields,
    DefaultTruckingComment,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    FieldInCard,
    NestedField,
} from 'app/types/form.types'
import {
    AddressCategory,
} from 'app/types/enums'

type Args = {
    forwarderId: number,
    pickupLocationId: number,
    deliveryLocationId: number,
    transportModeRoad: TransportModeRoad,
    transportModeForwarder: TransportModeRoad,
    isFirstStep: boolean,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    isRoadDelivery: boolean,
    freightRateServiceProviderCode?: string,
    countryName?: string,
    zip?: string,
}

const getInternalRoadFormFields = ({
    forwarderId,
    pickupLocationId,
    deliveryLocationId,
    transportModeRoad,
    transportModeForwarder,
    getAllowedValues,
    isRoadDelivery,
    freightRateServiceProviderCode,
    countryName,
    zip,
}: Args): FieldInCard<OrderStepFields>[] => {
    const transportMode = isRoadDelivery ? transportModeForwarder : transportModeRoad

    const trucksAndVans: FieldInCard<OrderStepFields>[] = [
        {
            name: 'numberOfTrucks',
            componentName: 'IntegerInput',
            className: 'numberOfTrucks',
            labelKey: 'OrderStep.numberOfTrucks',
            disabled: true,
        },
        {
            name: 'numberOfVans',
            componentName: 'IntegerInput',
            className: 'numberOfVans',
            labelKey: 'OrderStep.numberOfVans',
            disabled: true,
        },
    ]

    return [
        {
            id: 'column1',
            fields: [
                {
                    name: 'forwarder',
                    componentName: 'LocationObjectSelector',
                    freightRateServiceProviderCode,
                    required: true,
                    labelKey: 'OrderStep.forwarder',
                },
                {
                    name: 'forwarderContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: true,
                    selectedLocation: forwarderId,
                    required: true,
                    labelKey: 'OrderStep.forwarderContacts',
                },
                {
                    name: 'transportModeRoad',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(TransportModeRoad),
                    labelKey: 'OrderStep.transportModeRoad',
                    disabled: true,
                },
                ...isRoadDelivery ? [{
                    name: 'transportModeForwarder',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(TransportModeRoad),
                    labelKey: 'Order.transportModeForwarder',
                    disabled: true,
                }] as NestedField<OrderStepFields>[] : [],
                ...transportMode === TransportModeRoad.FTL ? trucksAndVans as [] : [],
                {
                    name: 'isTemperatureControlled',
                    componentName: 'Checkbox',
                    labelKey: 'OrderStep.isTemperatureControlled',
                    disabled: true,
                },
                {
                    name: 'defaultTruckingComment',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DefaultTruckingComment),
                    useDropdown: true,
                    required: true,
                    labelKey: 'OrderStep.defaultTruckingComment',
                },
                {
                    name: 'truckingComment',
                    className: 'truckingComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.truckingComment',
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'pickupLocation',
                    componentName: 'LocationObjectSelector',
                    isShippingLocation: true,
                    labelKey: 'OrderStep.pickupLocation',
                    disabled: true,
                },
                {
                    name: 'pickupLocationContacts',
                    componentName: 'MultipleContactObjectSelector',
                    required: true,
                    labelKey: 'OrderStep.pickupLocationContacts',
                },
                {
                    name: 'requestedPickup',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: pickupLocationId,
                    required: true,
                    labelKey: 'OrderStep.requestedPickup',
                },
                {
                    name: 'pickupComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.pickupComment',
                    disabled: true,
                },
            ],
        },
        {
            id: 'column3',
            fields: [
                {
                    name: 'deliveryLocation',
                    componentName: 'LocationObjectSelector',
                    isShippingLocation: true,
                    includeFilters: {
                        countryIsoAlpha2Code: countryName ? [countryName] : undefined,
                        zip: zip ? [zip] : undefined,
                    },
                    filterAddressCategory: [AddressCategory.SERVICECENTER],
                    required: true,
                    labelKey: 'OrderStep.deliveryLocation',
                },
                {
                    name: 'deliveryLocationContacts',
                    componentName: 'MultipleContactByLocationObjectSelector',
                    existedForm: true,
                    selectedLocation: deliveryLocationId,
                    required: true,
                    labelKey: 'OrderStep.deliveryLocationContacts',
                },
                {
                    name: 'requestedDelivery',
                    componentName: 'DateTimeZonedFromLocation',
                    locationId: deliveryLocationId,
                    required: true,
                    labelKey: 'OrderStep.requestedDelivery',
                },
                {
                    name: 'deliveryComment',
                    componentName: 'LongText',
                    labelKey: 'OrderStep.deliveryComment',
                },
            ],
        },
        {
            id: 'column4',
            fields: [],
        },
    ]
}

export default getInternalRoadFormFields
