import React, {
    FunctionComponent, SVGProps,
} from 'react'
import clsx from 'clsx'
import {
    NavLink,
    useLocation,
} from 'react-router-dom'
import {
    Typography,
} from '@mui/material'
import {
    Link,
} from 'app/MainMenu/MainMenu.types'
import {
    APPS,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'

import useStyles from './ActiveTab.style'

const defaultProps = {
    className: '',
}

export default function ActiveTab({
    icon: Icon,
    links,
    className,
    module,
}: Readonly<{
    icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string; }>,
    links: Link[],
    className?: string,
    module?: string,
}>) {
    const {
        pathname,
    } = useLocation()

    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.iconContainer}>
                <Icon
                    className={clsx(
                        classes.icon,
                        classes[className],
                    )}
                />
            </div>
            <div
                className={classes.linkContainer}
            >
                {links.map(({
                    url,
                    title,
                    id,
                }) => {
                    return (
                        <NavLink
                            key={`active-tab-links-${title}`}
                            to={url}
                            data-testid={`active-tab-links-${id}`}
                            className={clsx(
                                {
                                    [classes.link]: true,
                                    [classes.activeLink]:
                                        pathname.replace(`/${APPS}/${module}`, '')
                                            .split('/').includes(id),
                                },
                            )}
                        >
                            <Typography
                                variant="button"
                            >
                                {title}
                            </Typography>
                        </NavLink>
                    )
                })}
            </div>
        </div>
    )
}

ActiveTab.defaultProps = defaultProps
