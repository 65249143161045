import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    AccountLaneForm, LaneStatus,
} from '../AccountLanes.types'
import {
    AccountLaneFormFieldsArgs,
} from '../AccountLaneForm/AccountLaneForm'

export default ({
    getAllowedValues,
}: Partial<AccountLaneFormFieldsArgs>): DTOCardFormField <AccountLaneForm>[] => {
    return [{
        id: 'general',
        title: 'General',
        fields: [
            {
                id: 'column1',
                fields: [{
                    name: 'laneNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.laneNumber',
                    disabled: true,
                }],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'laneStatus',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(LaneStatus),
                        labelKey: 'LaneQuoteRequest.selectedLaneStatus',
                        disabled: true,
                    },
                    {
                        name: 'expPalletsYear',
                        required: true,
                        componentName: 'InputSingleline',
                        labelKey: 'LaneBasicView.expPalletsYear',
                    },
                    {
                        name: 'laneComment',
                        componentName: 'LongText',
                        labelKey: 'AccountLane.laneComment',
                    },
                ],
            },
        ],
    }]
}
