import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    ContainerCode,
    Currency,
} from 'app/types/enums'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    ContainerUnitPriceTable,
} from './ProformaInvoices.types'
import {
    CONTAINER_UNIT_RRICE,
} from './ProformaInvoices.requests'

export default (): SkyNetTableConfig<keyof ContainerUnitPriceTable> => {
    return {
        name: CONTAINER_UNIT_RRICE,
        tabs: [{
            name: 'All',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'priority',
                    labelKey: 'ProformaInvoices.priority',
                    reduced: true,
                },
                {
                    name: 'containerCodes',
                    labelKey: 'ProformaInvoices.containerCodes',
                    componentName: 'TableMultipleEnumValues',
                    allowedValuesEnum: ContainerCode,
                    reduced: true,
                },
                {
                    name: 'currency',
                    labelKey: 'ProformaInvoices.currency',
                    componentName: 'EnumValue',
                    allowedValuesEnum: Currency,
                    filterField: 'currency',
                    filterType: FilterType.Array,
                },
                {
                    name: 'countriesCountryName',
                    labelKey: 'ProformaInvoices.countries',
                    filterField: 'countriesCountryName',
                    filterType: FilterType.Array,
                    componentName: 'TableMultipleValues',
                },
                {
                    name: 'pricingAccountsCompanyName',
                    labelKey: 'ProformaInvoices.pricingAccounts',
                    filterField: 'pricingAccountsCompanyName',
                    filterType: FilterType.Array,
                    componentName: 'TableMultipleValues',
                },
                {
                    name: 'unitValue',
                    labelKey: 'ProformaInvoices.unitValue',
                },
            ],
        }],

    }
}
