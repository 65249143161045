import React, {
    useCallback, useMemo,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import useValidation from 'app/hooks/useValidation/useValidation'
import useOrderManagementRoutes from 'app/Apps/OrderManagement/hooks/useOrderManagementRoutes'
import useGetProductTypesForTempRange
    from 'app/Apps/PreBooking/components/PreBookingLaneDetails/hooks/useGetProductTypesForTempRange'

import useLongTermLeaseCreateContext from '../../LongTermLeaseCreateContext'
import {
    LongTermLeaseType,
} from '../../../longTermLease.types'
import useLongTermLeaseCreate from '../../../hooks/useLongTermLeaseCreate'
import getFields from './containerDetails.fields'

const ContainerDetails = () => {
    const {
        activeStep,
        setActiveStep,
        setIsEdited,
        setValue,
        value,
    } = useLongTermLeaseCreateContext()

    const {
        LongTermLease: LongTermLeaseRoutes,
    } = useOrderManagementRoutes()

    const {
        productTypes,
    } = useGetProductTypesForTempRange({
        tempRange: value.secondStep.tempRange,
    })

    const onSuccessCreate = ({
        id,
    }: {
        id: number,
    }) => {
        LongTermLeaseRoutes.Overview.navigate({
            params: {
                key: id,
            },
        })
    }

    const {
        mutate,
        isPending,
    } = useLongTermLeaseCreate(onSuccessCreate)

    const fields = useMemo(() => {
        return getFields(productTypes)
    }, [productTypes])

    const {
        isValid,
    } = useValidation({
        fields,
        values: value.thirdStep,
    })

    const onChange = (newVal: Partial<LongTermLeaseType>) => {
        setValue({
            ...value,
            thirdStep: newVal,
        })
    }

    const handleNextAction = useCallback(() => {
        mutate({
            ...value.firstStep,
            ...value.secondStep,
            ...value.thirdStep,
        } as LongTermLeaseType)
        setIsEdited(false)
    }, [
        mutate,
        setIsEdited,
        value.firstStep,
        value.secondStep,
        value.thirdStep,
    ])

    const backAction = useCallback(() => {
        setActiveStep(activeStep - 1)
        setValue({
            ...value,
            thirdStep: {},
        })
    }, [
        setActiveStep,
        activeStep,
        setValue,
        value,
    ])

    return (
        <>
            <DomainObjectForm
                value={value.secondStep}
                onChange={onChange}
                fields={fields}
                name="OrderByNumberView"
            />
            <SkyNetStepperButtons
                isValid={isValid}
                nextLabel="Create"
                nextAction={handleNextAction}
                canGoBack
                backAction={backAction}
                isLoading={isPending}
            />
        </>
    )
}

export default ContainerDetails
