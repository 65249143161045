import {
    IncoTerm,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    OrderTemperatureRange, RegionFirstLevel,
} from 'app/types/enums'
import {
    FieldInCard, FormPart, NestedField,
} from 'app/types/form.types'

import {
    AutomaticallyFormType, DestinationSelection,
    TransportModes,
} from '../../createAutomaticallyForm.types'

type Args = {
    originLocation: {id: number},
    temperatureRange: OrderTemperatureRange,
    selectPreviousOrders: boolean,
    getAllowedValues: (enumType: any) => Record<string, string>,
    destinationSelection: DestinationSelection,
    disabled: boolean,
}

const getFields = ({
    originLocation,
    temperatureRange,
    selectPreviousOrders,
    getAllowedValues,
    destinationSelection,
    disabled,
}: Args): FormPart<keyof AutomaticallyFormType>[] => {
    const previousOrders: FieldInCard<keyof AutomaticallyFormType>[] = [{
        title: 'Previous Orders',
        id: 'previousOrders',
        className: 'previousOrders',
        classNames: (classes) => {
            return {
                wrapper: classes.wrapper,
                title: classes.title,
            }
        },
        fields: [{
            name: 'previousOrders',
            componentName: 'PreviousOrdersITCreationForm',
            location: originLocation?.id,
        }],
    }]

    return [
        {
            title: 'Order Information',
            className: 'orderInformation',
            id: 'orderInformation',
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'originLocation',
                            componentName: 'LocationObjectSelector',
                            required: true,
                            labelKey: 'OrderByNumberView.originLocation',
                        },
                        {
                            name: 'destinationSelection',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(DestinationSelection),
                            noSort: true,
                            required: true,
                            labelKey: 'AccountLane.destinationAirport',
                        },
                        ...destinationSelection === DestinationSelection.LOCATION
                            ? [{
                                name: 'destinationLocation',
                                componentName: 'LocationObjectSelector',
                                required: true,
                                labelKey: 'OrderByNumberView.destinationLocation',
                            }] as NestedField<keyof AutomaticallyFormType>[] : [],
                        ...destinationSelection === DestinationSelection.REGION ? [{
                            name: 'destinationRegion',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(RegionFirstLevel),
                            required: true,
                            labelKey: 'Opportunities.destinationRegion',
                        }] as NestedField<keyof AutomaticallyFormType>[] : [],
                        ...destinationSelection === DestinationSelection.COUNTRY ? [{
                            name: 'destinationCountry',
                            componentName: 'CountryDataSelector',
                            labelKey: 'OrderView.destinationCountry',
                            required: true,
                        }] as NestedField<keyof AutomaticallyFormType>[] : [],
                        {
                            name: 'temperatureRange',
                            componentName: 'EnumSelector',
                            useDropdown: true,
                            allowedValues: getAllowedValues(OrderTemperatureRange),
                            labelKey: 'Order.temperatureRange',
                        },
                    ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'requestedPickup',
                            componentName: 'DateTimeZonedFromLocation',
                            locationId: originLocation?.id,
                            required: true,
                            isDisableWeekends: true,
                            labelKey: 'OrderStep.requestedPickup',
                        },
                        {
                            name: 'transportModes',
                            componentName: 'EnumChipSelector',
                            allowedValues: getAllowedValues(TransportModes),
                            required: true,
                            labelKey: 'InternalTransportCreateDto.transportModes',
                        },
                        {
                            name: 'selectPreviousOrders',
                            componentName: 'Checkbox',
                            title: 'Select Previous Orders?',
                            disabled: !originLocation?.id || disabled,
                            labelKey: 'Order.selectPreviousOrders',
                        },
                        {
                            name: 'incoTerm',
                            componentName: 'Select',
                            allowedValues: getAllowedValues(IncoTerm),
                            required: true,
                            labelKey: 'OrderByNumberView.incoTerm',
                        },
                    ],
                },
                {
                    id: 'column3',
                    fields: [
                        {
                            name: 'maxLeadTime',
                            componentName: 'IntegerInput',
                            labelKey: 'InternalTransportCreateDto.maxLeadTime',
                        },
                        {
                            name: 'orderComment',
                            componentName: 'LongText',
                            multiline: true,
                            labelKey: 'Order.orderComment',
                        },
                        {
                            name: 'requiredCargoComment',
                            componentName: 'LongText',
                            multiline: true,
                            labelKey: 'Order.requiredCargoComment',
                        },
                    ],
                },
                {
                    id: 'column4',
                    fields: [{
                        name: 'requiredContainers',
                        componentName: 'RequiredContainersByContainerType',
                        temperatureRange,
                        required: true,
                    }],
                },
            ],
        },
        ...originLocation?.id && selectPreviousOrders ? previousOrders : [],
    ]
}

export default getFields
