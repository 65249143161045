/* eslint-disable object-curly-newline */
import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {},
        btnContainer: {
            paddingTop: theme.spacing(1),
        },
        gridContainer: {
            margin: '0 !important',
        },
        header: {
            padding: '0 !important',
        },
        btn: {
            width: '100%',
            backgroundColor: 'transparent',
            border: 0,
            letterSpacing: 0,
            color: '#57B1CF',
            textTransform: 'uppercase',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'end',
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: 500,
            padding: 0,
        },
        removeHeaderFiller: {
            width: '24px',
        },
        btnDisabled: {
            color: '#B7B7B7',
        },
        addIcon: {
            color: '57B1CF',
        },
        label: {

        },
        rightAlign: {
            textAlign: 'right',
        },
    }
})

export default useStyles
