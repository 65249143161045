import {
    RequestConfig,
} from 'app/types/request.types'

type LongTermLeaseRequests = {
    Create: ({
        data,
    }) => RequestConfig,
}

const longTermLeaseRequests: LongTermLeaseRequests = {
    Create: ({
        data,
    }) => {
        return {
            url: 'longtermlease',
            method: 'POST',
            data,
        }
    },
}

export default longTermLeaseRequests
