import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    OrderStatuses,
    ProductType,
} from 'app/types/enums'
import {
    CustomerTransportTableType,
    OrderSubType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    CustomerOrderSource,
} from './CustomerTransport.types'

export default (): SkyNetTableConfig<keyof CustomerTransportTableType> => {
    return {
        name: 'OrderManagement.CT_Orders',
        tabs: [{
            name: 'All Orders',
            url: CommonRoutes.ALL,
            requestUrl: 'all-customer-transport',
            fields: [
                {
                    name: 'orderSubType',
                    componentName: 'EnumValue',
                    allowedValuesEnum: OrderSubType,
                    reduced: true,
                    filterField: 'orderSubType',
                    filterType: FilterType.Array,
                },
                {
                    name: 'orderNumber',
                    reduced: true,
                },
                {
                    name: 'from',
                    filterField: 'from',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'to',
                    filterField: 'to',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'destinationCountry',
                    filterField: 'destinationCountry',
                    filterType: FilterType.Array,
                },
                {
                    name: 'pricingAccountCompanyName',
                    filterField: 'pricingAccountCompanyName',
                    filterType: FilterType.Array,
                    labelKey: 'Prebooking.pricingAccount',
                },
                {
                    name: 'customerLocationName',
                    filterField: 'customerLocationName',
                    filterType: FilterType.Array,
                    labelKey: 'ContainerSelectionView.customerLocationName',
                },
                {
                    name: 'originLocationLocationName',
                    filterField: 'originLocationLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'forwarderLocationName',
                    filterField: 'forwarderLocationName',
                    filterType: FilterType.Array,
                    labelKey: 'Order.forwarderPositioning',
                },
                {
                    name: 'handoverPointLocationName',
                    filterField: 'handoverPointLocationName',
                    filterType: FilterType.Array,
                    labelKey: 'LaneGroup.handoverPoint',
                },
                {
                    name: 'plannedPickup',
                    labelKey: 'PostponementOrderDto.requestedPickup',
                    filterField: 'plannedPickup',
                    filterType: FilterType.DateRange,
                },
                {
                    name: 'requestedHandover',
                    filterField: 'requestedHandover',
                    filterType: FilterType.DateRange,
                },
                {
                    name: 'destinationLocationLocationName',
                    filterField: 'destinationLocationLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'requiredContainers',
                    componentName: 'RequiredContainersList',
                    allowedValuesEnum: ProductType,
                    sorting: false,
                    labelKey: 'Order.requiredContainers',
                },
                {
                    name: 'mawbNumber',
                    filterField: 'mawbNumber',
                    filterType: FilterType.Array,
                },
                {
                    name: 'airlineCompanyName',
                    filterField: 'airlineCompanyName',
                    filterType: FilterType.Array,
                    labelKey: 'OrderStep.airline',
                },
                {
                    name: 'source',
                    filterField: 'source',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: CustomerOrderSource,
                    isHidden: true,
                    labelKey: 'OrderByNumberView.source',
                },
                {
                    name: 'orderStatus',
                    filterField: 'orderStatus',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: OrderStatuses,
                    labelKey: 'OrderView.orderStatus',
                },
            ],
        }],
    }
}
