import {
    IncoTerm,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    OrderTemperatureRange, RegionFirstLevel,
} from 'app/types/enums'
import {
    OrderStep,
    PreviousOrder,
    RequiredContainersInternal,
} from 'app/Apps/OrderManagement/Orders/orders.types'

export enum TransportModes {
    ROAD = 'ROAD', SEA = 'SEA', AIR = 'AIR'
}

export enum ContainerLoad {
    FTL = 'FTL',
    LTL = 'LTL',
}

enum SeaContainerLoad {
    FCL = 'FCL',
    LCL = 'LCL',
}

export enum DestinationSelection {
    LOCATION = 'LOCATION',
    COUNTRY = 'COUNTRY',
    REGION = 'REGION',
}

export type BestOptionType = {
    transportMode: TransportModes,
    exportForwarder: string,
    destinationLocation: string,
    importForwarder: string,
    originPort: string,
    destinationPort: string,
    validUntil: string,
    forwarder: string,
    leadTime: number,
    price: number,
    containerLoad: ContainerLoad,
    seaContainerLoad: SeaContainerLoad,
    rateIdCombined: string,
    destinationZipCode: string,
    destinationCountry: string,
}

export type AutomaticallyFormType = {
    destinationSelection: DestinationSelection
    originLocation: {
        id: number
    },
    destinationLocation?: {
        id: number
    },
    destinationCountry?: {
        id: number
    },
    transportModes: TransportModes[],
    maxLeadTime: number,
    requestedPickup: string,
    selectPreviousOrders: boolean,
    incoTerm: IncoTerm,
    temperatureRange: OrderTemperatureRange,
    orderComment: string,
    requiredCargoComment: string,
    requiredContainers: RequiredContainersInternal[],
    previousOrders?: PreviousOrder[],
    orderSteps?: Partial<OrderStep>[],
    bestOption?: BestOptionType,
    destinationRegion?: RegionFirstLevel,
}
