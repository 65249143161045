import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    LaneGroupTemperatureRange,
} from 'app/types/enums'
import {
    IncoTerm,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import {
    LongTermLeaseType,
} from '../../../longTermLease.types'

const getFields = (
    getAllowedValues: (param: Record<string, string>) => Record<string, string>,
): DTOCardFormField<keyof LongTermLeaseType>[] => {
    return [{
        title: 'Contract Details',
        className: 'contractDetails',
        id: 'contractDetails',
        fields: [
            {
                id: 'contractDetailsColumn1',
                fields: [
                    {
                        name: 'customerReference',
                        componentName: 'InputSingleline',
                        labelKey: 'Order.customerReference',
                    },
                    {
                        name: 'receivedOn',
                        componentName: 'DateTimeZonedSelect',
                        required: true,
                        labelKey: 'LongTermLeaseForm.receivedOn',
                    },
                    {
                        name: 'enteredOn',
                        componentName: 'DateTimeZonedSelect',
                        labelKey: 'LongTermLeaseForm.enteredOn',
                    },
                    {
                        name: 'tempRange',
                        componentName: 'EnumSelector',
                        useDropdown: true,
                        allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                        labelKey: 'LaneGroup.tempRange',
                    },
                    {
                        name: 'incoTerm',
                        componentName: 'Select',
                        allowedValues: getAllowedValues(IncoTerm),
                        labelKey: 'LaneOpsInformationDto.incoTerm',
                    },
                ],
            },
            {
                id: 'contractDetailsColumn2',
                fields: [
                    {
                        name: 'leaseStartTimestamp',
                        componentName: 'DateTimeZonedSelect',
                        required: true,
                        labelKey: 'OrderBilling.leaseStartTimestamp',
                    },
                    {
                        name: 'leaseEndTimestamp',
                        componentName: 'DateTimeZonedSelect',
                        labelKey: 'OrderBilling.leaseEndTimestamp',
                    },
                    {
                        name: 'commentForBillingOps',
                        componentName: 'LongText',
                        labelKey: 'OrderByNumberView.commentForBillingOps',
                    },
                ],
            },
        ],
    }]
}

export default getFields
