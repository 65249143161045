import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useHasPermission from 'app/hooks/useHasPermission/useHasPermission'

import getTableConfig from './longTermLease.config'
import useOrderManagementRoutes from '../hooks/useOrderManagementRoutes'
import LongTermLeaseCreate from './LongTermLeaseCreate'
import LongTermLeaseOverview from './LongTermLeaseOverview'

const tableControls: TableControlTypes[] = [
    TableControlTypes.Search,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const LongTermLease = () => {
    const {
        LongTermLease: LongTermLeaseRoutes,
    } = useOrderManagementRoutes()

    const canCreate = useHasPermission(['long_term_lease_create'])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    const tabs: SidebarTab[] = useTabsByPermission([{
        tab: {
            label: 'Overview',
            url: LongTermLeaseRoutes.Overview.route,
            Component: LongTermLeaseOverview,
        },
        permission: true,
    }])

    return (
        <SkyNetDomain
            name="LongTermLease"
            sidebarTabs={tabs}
            defaultTab={LongTermLeaseRoutes.Overview.route}
            actionComponents={{
                Create: canCreate ? LongTermLeaseCreate : null,
            }}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
            />
        </SkyNetDomain>
    )
}

export default LongTermLease
