import React, {
    useState, useMemo, useCallback,
} from 'react'
import {
    SkyNetConfirmationContext, SkyNetConfirmationContextType,
} from './useSkyNetConfirmationContext'

import {
    SkyNetConfirmationProps,
} from './SkyNetConfirmation.types'
import ConfirmationDialog from '../ConfirmationDialog'

export default function SkyNetConfirmation({
    dialogContent,
    dialogTitle,
    positiveLabel,
    isPositiveAlert,
    positiveLabelDisabled,
    negativeLabel,
    classNames,
    children,
}: Readonly<SkyNetConfirmationProps & {
    children: JSX.Element
}>) {
    const [
        open,
        setOpen,
    ] = useState<boolean>(false)
    const [
        action,
        setAction,
    ] = useState<() => void>(undefined)

    const context = useMemo(() => {
        return {
            setAction,
            setOpen,
        } as SkyNetConfirmationContextType
    }, [])

    const handleClose = useCallback((positive) => {
        if (positive && action && open) {
            action()
        }
        setOpen(null)
    }, [
        action,
        open,
    ])

    return (
        <SkyNetConfirmationContext.Provider value={context}>
            <>
                {children}
                <ConfirmationDialog
                    open={open}
                    handleClose={handleClose}
                    dialogTitle={dialogTitle}
                    dialogContent={dialogContent}
                    positiveLabel={positiveLabel}
                    isPositiveAlert={isPositiveAlert}
                    positiveLabelDisabled={positiveLabelDisabled}
                    negativeLabel={negativeLabel}
                    classNames={classNames}
                />
            </>
        </SkyNetConfirmationContext.Provider>
    )
}
