import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    ContainerCode, Currency,
} from 'app/types/enums'
import {
    ContainerUnitPrice,
} from '../ProformaInvoices.types'

export default (): DTOCardFormField<keyof ContainerUnitPrice>[] => {
    return [{
        title: 'General',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'priority',
                        labelKey: 'ProformaInvoices.priority',
                        componentName: 'IntegerInput',
                        required: true,
                    },
                    {
                        name: 'containerCodes',
                        labelKey: 'ProformaInvoices.containerCodes',
                        componentName: 'MultipleSelect',
                        allowedValues: ContainerCode,
                        required: true,
                    },
                    {
                        name: 'currency',
                        labelKey: 'ProformaInvoices.currency',
                        componentName: 'EnumSelector',
                        allowedValues: Currency,
                        useDropdown: true,
                        required: true,
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'countries',
                        labelKey: 'ProformaInvoices.countries',
                        componentName: 'MultipleCountriesSelector',
                    },
                    {
                        name: 'pricingAccounts',
                        labelKey: 'ProformaInvoices.pricingAccounts',
                        componentName: 'MultipleAccountSelector',
                    },
                    {
                        name: 'unitValue',
                        labelKey: 'ProformaInvoices.unitValue',
                        componentName: 'IntegerInput',
                        required: true,
                    },
                ],
            },
        ],
    }]
}
