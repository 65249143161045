export enum GatewayStatus {
    TO_BE_INSTALLED = 'TO_BE_INSTALLED',
    STOCK = 'STOCK',
    INSTALLED = 'INSTALLED',
    EXPERIMENTAL = 'EXPERIMENTAL',
    UNKNOWN = 'UNKNOWN',
    DECOMMISSIONED = 'DECOMMISSIONED',
    UNDER_INVESTIGATION = 'UNDER_INVESTIGATION',
}

export enum GatewayType {
    CARTASENSE_ETHERNET = 'CARTASENSE_ETHERNET',
    CARTASENSE_GSM = 'CARTASENSE_GSM',
    CARTASENSE_2G = 'CARTASENSE_2G',
    LORA_MIROMICO = 'LORA_MIROMICO',
    MR900= 'MR900',
}

export enum GatewayMainType {
    CARTASENSE = 'CARTASENSE',
    LORA = 'LORA',
}

export enum GatewayStorageArea {
    C = 'C',
    CRT = 'CRT',
    DRY = 'DRY',
    OPERATIONAL = 'OPERATIONAL',
    RECEPTION = 'RECEPTION',
    FREEZER = 'FREEZER',
    OFFICE = 'OFFICE',
    BACKUP = 'BACKUP',
    PRECONUNIT = 'PRECONUNIT',
    TRAILER = 'TRAILER',
    UNKNOWN = 'UNKNOWN'
}

export enum GatewayDataPlan {
    LAN = 'LAN',
    WLAN = 'WLAN',
    LOCALSIM = 'LOCALSIM',
    TELECOM26 = 'TELECOM26',
    SWISSCOM = 'SWISSCOM',
}

export enum GatewayLocationCategory {
    SERVICECENTER = 'SERVICECENTER',
    AIRPORTSERVICECENTER = 'AIRPORTSERVICECENTER',
    CUSTOMER = 'CUSTOMER',
    REVERSELOGISTICS = 'REVERSELOGISTICS',
    INTERNAL = 'INTERNAL',
    FREIGHTFORWARDER = 'FREIGHTFORWARDER',
}

export enum GatewayHardwareVersion {
    V_1_0 = 'V_1_0',
    V_2_0 = 'V_2_0',
    V_2_1 = 'V_2_1',
}

export enum GatewaySoftwareVersion {
    V_2_0_0 = 'V_2_0_0',
    V_2_1_0 = 'V_2_1_0',
    V_2_2_0 = 'V_2_2_0',
    V_2_3_0 = 'V_2_3_0',
    V_2_4_0 = 'V_2_4_0',
}
