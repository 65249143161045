import {
    RequestConfig, SelectorConfig,
} from 'app/types/request.types'
import {
    Country,
} from './Countries.types'

type CountriesRequestConfig = {
    domainName: string,
    get: ({
        id: number,
    }) => RequestConfig,
    getAll: (data?: Record<string, any>) => RequestConfig,
    selector: SelectorConfig<Partial<Country>[]>
}

const CountriesRequest: CountriesRequestConfig = {
    domainName: 'Country',
    get: ({
        id,
    }) => {
        return {
            url: `country/${id}`,
        }
    },
    getAll: (data) => {
        return {
            url: 'country/filter-request/selector',
            method: 'POST',
            data,
        }
    },
    selector: {
        url: 'country/filter-request/selector',
        convertion: (countries: Country[]) => {
            return countries.map(({
                countryName, id,
            }) => {
                return {
                    id,
                    label: countryName,
                    value: id,
                    countryName,
                }
            })
        },
    },
}

export default CountriesRequest
