import {
    useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'

import {
    useAppDispatch,
    useAppSelector,
} from 'app/store/hooks'
import {
    setConfig,
    isConfigSet,
} from 'app/store/Configs'
import {
    SharedConfigPropNames,
} from 'app/Apps/DomainObject/ComponentMapper/ComponentTableCollection.types'
import {
    SkyNetTableConfig, SkyNetTableConfigWithTabs,
} from 'app/shared-components/SkyNetTable'

const storeConfig: SharedConfigPropNames[] = [
    'allowedValuesEnum',
    'enumsConfig',
    'labelPrefixKeys',
    'alertDays',
    'type',
    'size',
    'linkConfig',
    'notificationIconProps',
    'className',
    'state',
]

export default (tableConfig: SkyNetTableConfig): void => {
    const dispatch = useAppDispatch()
    const isConfigExist = useAppSelector((state) => {
        return isConfigSet(state, tableConfig.name)
    })

    const config = useMemo(() => {
        if (!tableConfig.name) {
            return null
        }

        let fields

        if ('tabs' in tableConfig && tableConfig.tabs.length) {
            fields = (tableConfig as SkyNetTableConfigWithTabs).tabs.reduce((acc, tab) => {
                return [
                    ...acc,
                    ...tab.fields,
                ]
            }, [])
        } else {
            fields = ('fields' in tableConfig && (tableConfig.fields || []))
        }

        return fields.reduce((acc, fieldConfig) => {
            const fieldProps = pick(fieldConfig, storeConfig)

            if (isEmpty(fieldProps) || !(fieldConfig.name || fieldConfig.id)) {
                return acc
            }

            return {
                ...acc,
                [fieldConfig.name || fieldConfig.id]: fieldProps,
            }
        }, {})
    }, [tableConfig])

    if (tableConfig?.name && !isEmpty(config) && !isConfigExist) {
        dispatch(setConfig({
            [tableConfig.name]: config,
        }))
    }
}
