import {
    RequestConfig,
} from 'app/types/request.types'

type ProformaInvoicesRequests = {
    create: () => RequestConfig,
    update: ({
        id,
    }: {id: number}) => RequestConfig,
    get: ({
        id,
    }: {id: number}) => RequestConfig,
}

export const CONTAINER_UNIT_RRICE = 'containerunitpricerule'

const ProformaInvoicesRequests: ProformaInvoicesRequests = {
    create: () => {
        return {
            method: 'POST',
            url: CONTAINER_UNIT_RRICE,
        }
    },
    update: ({
        id,
    }) => {
        return {
            method: 'PATCH',
            url: `${CONTAINER_UNIT_RRICE}/${id}`,
        }
    },
    get: ({
        id,
    }) => {
        return {
            method: 'GET',
            url: `${CONTAINER_UNIT_RRICE}/${id}`,
        }
    },
}

export default ProformaInvoicesRequests
