import React, {
    useMemo,
    useState,
} from 'react'

import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'

import {
    LongTermLeaseType,
} from '../longTermLease.types'
import CustomerDetails from './steps/CustomerDetails'
import {
    LongTermLeaseCreateContext,
} from './LongTermLeaseCreateContext'
import BookingDetails from './steps/BookingDetails'
import ContainerDetails from './steps/ContainerDetails'

const getSteps = (activeStep: number) => {
    return [
        {
            key: '1',
            label: 'Customer Details',
            expanded: true,
            renderContent: ({
                index,
            }: {
                index: number,
            }) => {
                return (
                    <CustomerDetails
                        disabled={activeStep !== 0}
                        index={index}
                    />
                )
            },
        },
        {
            key: '2',
            label: 'Booking Details',
            expanded: (step) => {
                return step > 0
            },
            renderContent: ({
                index,
            }: {
                index: number,
            }) => {
                return (
                    <BookingDetails
                        disabled={activeStep !== 1}
                        index={index}
                    />
                )
            },
        },
        {
            key: '3',
            label: 'Container Details',
            expanded: (step) => {
                return step > 1
            },
            content: <ContainerDetails />,
        },
    ]
}

const LongTermLeaseCreate = () => {
    const [
        value,
        setValue,
    ] = useState<{
        firstStep: Partial<LongTermLeaseType>,
        secondStep: Partial<LongTermLeaseType>,
        thirdStep: Partial<LongTermLeaseType>,
    }>({
        firstStep: {},
        secondStep: {},
        thirdStep: {},
    })
    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(0)

    const [
        isEdited,
        setIsEdited,
    ] = useState<boolean>()

    useBeforeUnloadDialog(isEdited)

    const stepperSteps = useMemo(() => {
        return getSteps(activeStep)
    }, [activeStep])

    const longTermLeaseFormContext = useMemo(() => {
        return {
            activeStep,
            setActiveStep,
            value,
            setValue,
            setIsEdited,
        }
    }, [
        activeStep,
        setActiveStep,
        value,
        setValue,
    ])

    return (
        <LongTermLeaseCreateContext.Provider value={longTermLeaseFormContext}>
            <SkyNetStepper
                activeStep={activeStep}
                steps={stepperSteps}
            />
            <HistoryBlockPrompt
                when={isEdited}
                positiveLabel="Discard"
                isPositiveAlert
                promptText="You are about to leave this page, your progress will be lost. Would you like to discard your changes anyway?"
            />
        </LongTermLeaseCreateContext.Provider>
    )
}

export default LongTermLeaseCreate
