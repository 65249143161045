import React, {
    useMemo,
} from 'react'
import useEnumValues from 'app/hooks/useEnumValues'

import {
    useAppSelector,
} from 'app/store/hooks'
import {
    selectConfigByFieldName,
} from 'app/store/Configs'
import TableMultipleValues from '../TableMultipleValues'

const defaultProps = {
    value: [],
    configName: null,
}

export default function TableMultipleEnumValues({
    configName,
    mapCellProps,
    value,
}: Readonly<{
    configName?: string,
    mapCellProps: string,
    value?: string[],
}>) {
    const fieldConfig = useAppSelector((state) => {
        return selectConfigByFieldName(state, configName, mapCellProps)
    })

    const allowedValues = useEnumValues()(fieldConfig?.allowedValuesEnum)

    const labels = useMemo(() => {
        return value.map((item) => {
            return allowedValues[item] || item
        })
    }, [
        allowedValues,
        value,
    ])

    if (!value.length) {
        return null
    }

    return <TableMultipleValues value={labels} />
}

TableMultipleEnumValues.defaultProps = defaultProps
