import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    PreBookingEntity,
} from 'app/Apps/PreBooking/PreBooking.types'
import {
    ProductType,
} from 'app/types/enums'

export default ({
    allowedProductTypes,
}: {
    allowedProductTypes: ProductType[],
}): DTOCardFormField<keyof PreBookingEntity>[] => {
    return [{
        id: 'closePreBooking',
        title: '',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'lane',
                        componentName: 'LaneSelector',
                        labelKey: 'Prebooking.lane',
                    },
                    {
                        name: 'customerExecution',
                        componentName: 'LocationObjectSelector',
                        labelKey: 'Prebooking.customerExecution',
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'mawbNumber',
                        componentName: 'InputSingleline',
                        labelKey: 'Prebooking.mawbNumber',
                    },
                    {
                        name: 'customerReference',
                        componentName: 'InputSingleline',
                        labelKey: 'Prebooking.customerReference',
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'originAirport',
                        componentName: 'LocationObjectSelector',
                        labelKey: 'Prebooking.originAirport',
                    },
                    {
                        name: 'destinationAirport',
                        componentName: 'LocationObjectSelector',
                        labelKey: 'Prebooking.destinationAirport',
                    },
                ],
            },
            {
                id: 'column4',
                fields: [{
                    name: 'requestedHandover',
                    componentName: 'DateTimeZonedSelect',
                    labelKey: 'Prebooking.requestedHandover',
                }],
            },
            {
                id: 'column5',
                fields: [
                    {
                        name: 'requiredContainers',
                        componentName: 'RequiredContainerByProductTypeList',
                        labelKey: 'Prebooking.requiredContainers',
                        allowedProductTypes,
                    },
                    {
                        name: 'originServiceCenter',
                        componentName: 'LocationObjectSelector',
                        labelKey: 'Prebooking.originServiceCenter',
                    },
                ],
            },
        ],
    }]
}
