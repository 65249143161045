import React, {
    useMemo,
} from 'react'
import {
    Navigate,
} from 'react-router-dom'

import PreBookings from 'app/Apps/PreBooking'

import {
    CommonRoutes,
    useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import Pooling from './Pooling'
import DailyOps from './DailyOps'
import OpenBookings from './OpenBookings'
import Lanes from './Lanes'
import CustomerTransport from './Orders/CustomerTransport'
import InternalTransport from './Orders/InternalTransport'
import useOrderManagementRoutes from './hooks/useOrderManagementRoutes'
import LongTermLease from './LongTermLease'
import ProformaInvoices from './ProformaInvoices'

export default function OrderManagement() {
    const Routes = useOrderManagementRoutes()

    const routesConfig = useMemo(() => {
        return {
            [Routes.DailyOps.route]: <DailyOps />,
            [Routes.Pooling.route]: <Pooling />,
            [Routes.OpenBookings.route]: <OpenBookings />,
            [Routes.Lanes.route]: <Lanes />,
            [Routes.CustomerOrders.route]: <CustomerTransport />,
            [Routes.InternalOrders.route]: <InternalTransport />,
            [Routes.LongTermLease.route]: <LongTermLease />,
            [Routes.PreBookings.route]: <PreBookings />,
            [Routes.ProformaInvoices.route]: <ProformaInvoices />,
            [CommonRoutes.SLASH]: <Navigate
                to={Routes.DailyOps.route}
                replace
            />,
        }
    }, [Routes])

    return useSkyNetRoutes({
        routesConfig,
    })
}
