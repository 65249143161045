import {
    createContext, useContext,
} from 'react'

import {
    StepperContextType,
} from 'app/types/common.types'

import {
    PreBookingEntity,
} from '../../PreBooking.types'

export type PreBookingInfoContextType = StepperContextType<PreBookingEntity>

export const PreBookingInfoContext = createContext<PreBookingInfoContextType>(null)

const usePreBookingInfoContext = () => {
    return useContext<PreBookingInfoContextType>(PreBookingInfoContext)
}

export default usePreBookingInfoContext
