import useRequest, {
    RequestFn, RequestKeys,
} from 'app/hooks/useRequest'
import {
    ContainerUnitPrice,
} from '../ProformaInvoices.types'
import requests from '../ProformaInvoices.requests'

export default function useGetContainerUnitPrice(id: number) {
    return useRequest<ContainerUnitPrice>({
        key: RequestKeys.getContainerUnitPrice,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(requests.get),
        enabled: Boolean(id),
    })
}
