import React, {
    useCallback,
} from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import SkyNetConfirmation from 'app/shared-components/SkyNetConfirmation'
import {
    RequestMethods,
} from 'app/types/common.enums'
import ProformaInvoiceForm from '../ProformaInvoiceForm'
import useGetContainerUnitPrice from '../hooks/useGetContainerUnitPrice'
import requests from '../ProformaInvoices.requests'
import useOrderManagementRoutes from '../../hooks/useOrderManagementRoutes'

export default function ProformaInvoiceUpdate({
    id,
    disabled,
}: Readonly<{id: number, disabled: boolean}>) {
    const {
        ProformaInvoices: ProformaInvoicesRoutes,
    } = useOrderManagementRoutes()
    const {
        data,
        isDataReady,
        invalidate,
        isError,
        error,
        isFetching,
    } = useGetContainerUnitPrice(id)

    const onSuccess = useCallback(({
        action,
    }: {action: RequestMethods}) => {
        if (action === RequestMethods.DELETE) {
            ProformaInvoicesRoutes.navigate()
        }
        invalidate()
    }, [
        invalidate,
        ProformaInvoicesRoutes,
    ])

    return (
        <StatusHandler
            isError={isError}
            isSuccess={isDataReady}
            isFetching={isFetching}
            error={error}
        >
            <SkyNetConfirmation
                dialogContent={`${'You are about to permanently delete this record. Are you sure you want to proceed ?'}`}
                dialogTitle="Confirm delete"
                positiveLabel="Delete"
                negativeLabel="Cancel"
                isPositiveAlert
            >
                <ProformaInvoiceForm
                    disabled={disabled}
                    onSuccess={onSuccess}
                    onCancel={invalidate}
                    data={data}
                    requestParam={requests.update({
                        id,
                    })}
                />
            </SkyNetConfirmation>
        </StatusHandler>
    )
}
