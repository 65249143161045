import {
    useCallback, useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'

import {
    ConfigLogger, LoggerDataTypes,
} from 'app/Apps/Loggers/Loggers.types'
import {
    SENSOR_CODES_NAME,
} from 'app/utils/optionsLoggers'

import useGetSensorData from './useGetSensorData'

type Params = {
    serialNumber: string,
    from: Date,
    to: Date,
    configLoggers?: ConfigLogger[],
    otherLoggers?,
    isDateRange: boolean,
    showDoorEvents?: boolean,
    useStandardizedTimestamps: boolean,
    dataTypes?: LoggerDataTypes[],
    showIncludeInvalid?: boolean,
}

const useGetSensorDataForLogger = (props:Params) => {
    const {
        serialNumber,
        otherLoggers,
        configLoggers,
        from,
        to,
        isDateRange,
        showDoorEvents,
        useStandardizedTimestamps,
        dataTypes,
        showIncludeInvalid,
    } = props
    const otherLoggersFormatted = useMemo(() => {
        return !isEmpty(otherLoggers) ? otherLoggers.map(({
            value, loggerType,
        }) => {
            return {
                loggerNumber: value,
                loggerType,
            }
        }) : []
    }, [otherLoggers])

    const getAllLoggers = useCallback((loggerType: string[]) => {
        return [
            {
                loggerNumber: serialNumber,
                loggerType: loggerType[0],
            },
            ...otherLoggersFormatted,
        ]
    }, [
        serialNumber,
        otherLoggersFormatted,
    ])

    const sensorCodes = useMemo(() => {
        return configLoggers.reduce((acc, {
            sensorCodes: sensorCodesConfig,
            name,
        }) => {
            if (name === SENSOR_CODES_NAME && sensorCodesConfig) {
                return [
                    ...acc,
                    ...sensorCodesConfig,
                ]
            }
            return uniq(acc)
        }, [])
    }, [configLoggers])

    return useGetSensorData({
        from,
        to,
        configLoggers,
        isDateRange,
        showDoorEvents,
        getAllLoggers,
        url: 'sensordata/logger',
        additionalQueryData: {
            sensorCodes,
        },
        useStandardizedTimestamps,
        dataTypes,
        showIncludeInvalid,
    })
}

export default useGetSensorDataForLogger
