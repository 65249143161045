import React, {
    useMemo,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import {
    RequestConfig,
} from 'app/types/request.types'
import {
    RequestMethods,
} from 'app/types/common.enums'
import {
    ContainerUnitPrice,
} from '../ProformaInvoices.types'

import {
    CONTAINER_UNIT_RRICE,
} from '../ProformaInvoices.requests'
import getFields from './ProformaInvoiceForm.fields'

const defaultProps = {
    onSuccess: undefined,
    onCancel: undefined,
    data: {},
    disabled: false,
}

export default function ProformaInvoiceForm({
    onSuccess,
    onCancel,
    data,
    requestParam,
    disabled,
}: Readonly<{
    onSuccess?: (c: ContainerUnitPrice & {action: RequestMethods}) => void,
    onCancel?: () => void,
    data?: ContainerUnitPrice,
    requestParam: RequestConfig,
    disabled?: boolean,
}>) {
    const fields = useMemo(() => {
        return getFields()
    }, [])

    return (
        <FormWithControls
            onSuccess={onSuccess}
            value={data}
            name={CONTAINER_UNIT_RRICE}
            fields={fields}
            requestParams={requestParam}
            exists={Boolean(data?.id)}
            onCancel={onCancel}
            disabled={disabled}
            canDelete
        />
    )
}

ProformaInvoiceForm.defaultProps = defaultProps
