import React from 'react'
import ProformaInvoiceUpdate from '../ProformaInvoiceUpdate'

export default function ProformaInvoiceOverview({
    id,
}: {id: number}) {
    return (
        <ProformaInvoiceUpdate
            id={id}
            disabled
        />
    )
}
