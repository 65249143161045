// eslint-disable-next-line import/prefer-default-export
export enum OrderManagementDomains {
    DailyOps = 'daily-ops',
    Pooling = 'pooling',
    OpenBookings = 'open-bookings',
    Lanes = 'lanes',
    CustomerOrders = 'customer-orders',
    InternalOrders = 'internal-orders',
    PreBookings = 'pre-bookings',
    CreateAirlineLease = 'create-airline-lease',
    CreateDirectLease = 'create-direct-lease',
    LongTermLease = 'long-term-lease',
    ProformaInvoices = 'proforma-invoices',
}
