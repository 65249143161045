/* HERE WE STORE ONLY BE-RELATED ENUMS */
export {
    default as DamageType,
} from './damageType.enums'

export enum ProductType {
    VAL_1500C = 'VAL_1500C',
    VAL_2500C = 'VAL_2500C',
    VAL_1500CRT = 'VAL_1500CRT',
    VAL_2500CRT = 'VAL_2500CRT',
    VAL_1500XSCOL = 'VAL_1500XSCOL',
    VAL_1500XCOL = 'VAL_1500XCOL',
    VAL_1500XSCRT = 'VAL_1500XSCRT',
    VAL_1500XCRT = 'VAL_1500XCRT',
    VAL_1500F = 'VAL_1500F',
    VAL_1500DF = 'VAL_1500DF',
    VAL_1500DF_EURO = 'VAL_1500DF_EURO',
    VAL_1500DF_EUROMAX = 'VAL_1500DF_EUROMAX',
    VAL_770C = 'VAL_770C',
    VAL_770CRT = 'VAL_770CRT',
    VAL_ONEC = 'VAL_ONEC',
    VAL_ONECRT = 'VAL_ONECRT',
    VAL_PRIME_VIAL = 'VAL_PRIME_VIAL',
    VAL_PRIME_BOTTLE = 'VAL_PRIME_BOTTLE',
    VAL_1500XCOL_MONARCH = 'VAL_1500XCOL_MONARCH',
    VAL_1500XCRT_MONARCH = 'VAL_1500XCRT_MONARCH',
    VAL_1500DF_EUROMAX_MONARCH = 'VAL_1500DF_EUROMAX_MONARCH',
}

export enum FeeChargeability {
    FIXED_AMOUNT = 'FIXED_AMOUNT',
    INCLUDED = 'INCLUDED',
    PERCENTAGE_BASE_LEASE = 'PERCENTAGE_BASE_LEASE',
    PERCENTAGE_FIXED_AMOUNT_PERIOD = 'PERCENTAGE_FIXED_AMOUNT_PERIOD',
    PERCENTAGE_BASE_LEASE_POSITIONING_PERIOD = 'PERCENTAGE_BASE_LEASE_POSITIONING_PERIOD',
    FIXED_AMOUNT_PER_VEHICLE = 'FIXED_AMOUNT_PER_VEHICLE',
    FIXED_AMOUNT_PER_CONTAINER = 'FIXED_AMOUNT_PER_CONTAINER',
    FIXED_AMOUNT_CONTAINER_SPECIFIC = 'FIXED_AMOUNT_CONTAINER_SPECIFIC',
    FIXED_AMOUNT_PER_DAY_CONTAINER = 'FIXED_AMOUNT_PER_DAY_CONTAINER',
    UNSPECIFIED = 'UNSPECIFIED',
    FIXED_AMOUNT_PER_ORDER = 'FIXED_AMOUNT_PER_ORDER',
}

export enum RejectionReason {
    REJECTION_DOUBLE_SELECTION = 'REJECTION_DOUBLE_SELECTION',
    REJECTION_DAMAGED = 'REJECTION_DAMAGED',
    REJECTION_PREASSIGNED_NOT_SELECTED = 'REJECTION_PREASSIGNED_NOT_SELECTED',
    REJECTION_WRONG_LOCATION = 'REJECTION_WRONG_LOCATION',
    REJECTION_PRIORITIZATION = 'REJECTION_PRIORITIZATION',
    REJECTION_OTHER = 'REJECTION_OTHER',
}

export enum Timezone {
    MINUS_1200 = 'MINUS_1200',
    MINUS_1100 = 'MINUS_1100',
    MINUS_1000 = 'MINUS_1000',
    MINUS_0930 = 'MINUS_0930',
    MINUS_0900 = 'MINUS_0900',
    MINUS_0800 = 'MINUS_0800',
    MINUS_0700 = 'MINUS_0700',
    MINUS_0600 = 'MINUS_0600',
    MINUS_0500 = 'MINUS_0500',
    MINUS_0430 = 'MINUS_0430',
    MINUS_0400 = 'MINUS_0400',
    MINUS_0330 = 'MINUS_0330',
    MINUS_0300 = 'MINUS_0300',
    MINUS_0200 = 'MINUS_0200',
    MINUS_0100 = 'MINUS_0100',
    PLUS_0000 = 'PLUS_0000',
    PLUS_0100 = 'PLUS_0100',
    PLUS_0200 = 'PLUS_0200',
    PLUS_0300 = 'PLUS_0300',
    PLUS_0330 = 'PLUS_0330',
    PLUS_0400 = 'PLUS_0400',
    PLUS_0430 = 'PLUS_0430',
    PLUS_0500 = 'PLUS_0500',
    PLUS_0530 = 'PLUS_0530',
    PLUS_0545 = 'PLUS_0545',
    PLUS_0600 = 'PLUS_0600',
    PLUS_0630 = 'PLUS_0630',
    PLUS_0700 = 'PLUS_0700',
    PLUS_0800 = 'PLUS_0800',
    PLUS_0845 = 'PLUS_0845',
    PLUS_0900 = 'PLUS_0900',
    PLUS_0930 = 'PLUS_0930',
    PLUS_1000 = 'PLUS_1000',
    PLUS_1030 = 'PLUS_1030',
    PLUS_1100 = 'PLUS_1100',
    PLUS_1130 = 'PLUS_1130',
    PLUS_1200 = 'PLUS_1200',
    PLUS_1245 = 'PLUS_1245',
    PLUS_1300 = 'PLUS_1300',
    PLUS_1345 = 'PLUS_1345'
}

export enum RangeType {
    BUSINESS_DAYS = 'Business Days',
    CALENDAR_DAYS = 'Calendar Days',
    CALENDAR_DAYS_HOUR = 'Calendar Days Hours',
}

export enum Currency {
    USD = 'USD',
    EUR = 'EUR',
    CHF = 'CHF',
    GBP = 'GBP',
}

export enum ContainerType {
    MODEL_5BT = 'MODEL_5BT',
    MODEL_5C = 'MODEL_5C',
    MODEL_5CRT = 'MODEL_5CRT',
    MODEL_40 = 'MODEL_40',
    MODEL_40BT = 'MODEL_40BT',
    MODEL_40BT_CRT_C_F_DF = 'MODEL_40BT_CRT_C_F_DF',
    MODEL_40C = 'MODEL_40C',
    MODEL_40CRT= 'MODEL_40CRT',
    MODEL_40DF= 'MODEL_40DF',
    MODEL_40F= 'MODEL_40F',
    MODEL_770C= 'MODEL_770C',
    MODEL_770CRT= 'MODEL_770CRT',
    MODEL_1500C= 'MODEL_1500C',
    MODEL_1500CRT= 'MODEL_1500CRT',
    MODEL_1500DF= 'MODEL_1500DF',
    MODEL_1500DF_EURO= 'MODEL_1500DF_EURO',
    MODEL_1500DF_EURO_MAX= 'MODEL_1500DF_EURO_MAX',
    MODEL_1500DF_F= 'MODEL_1500DF_F',
    MODEL_1500F= 'MODEL_1500F',
    MODEL_1500X= 'MODEL_1500X',
    MODEL_1500X_C= 'MODEL_1500X_C',
    MODEL_1500X_COL= 'MODEL_1500X_COL',
    MODEL_1500X_COLG4= 'MODEL_1500X_COLG4',
    MODEL_1500X_COLG4_2= 'MODEL_1500X_COLG4_2',
    MODEL_1500X_CRT= 'MODEL_1500X_CRT',
    MODEL_1500X_CRTG4= 'MODEL_1500X_CRTG4',
    MODEL_1500X_CRTG4_2= 'MODEL_1500X_CRTG4_2',
    MODEL_1500X_ERT='MODEL_1500X_ERT',
    MODEL_1500X_ERTG4 = 'MODEL_1500X_ERTG4',
    MODEL_1500X_ERTG4_2 = 'MODEL_1500X_ERTG4_2',
    MODEL_1500X_G4 = 'MODEL_1500X_G4',
    MODEL_1500X_G4_2 = 'MODEL_1500X_G4_2',
    MODEL_2500C = 'MODEL_2500C',
    MODEL_2500CRT = '2500CRT',
    MODEL_2500C_CRT = 'MODEL_2500CRT',
    MODEL_2500DF = 'MODEL_2500DF',
    MODEL_2500F = 'MODEL_2500F',
    MODEL_2500X = 'MODEL_2500X',
    MODEL_2500X_C = 'MODEL_2500X_C',
    MODEL_2500X_CRT = 'MODEL_2500X_CRT',
    MODEL_LAB = 'MODEL_LAB',
    MODEL_ONE_C = 'MODEL_ONE_C',
    MODEL_ONE_CRT = 'MODEL_ONE_CRT',
    MODEL_PRIME_BOTTLE = 'MODEL_PRIME_BOTTLE',
    MODEL_PRIME_VIAL = 'MODEL_PRIME_VIAL',
}

export enum LeaseType {
    AIRLINE_LEASE = 'AIRLINE_LEASE',
    DIRECT_LEASE = 'DIRECT_LEASE',
}

export enum LaneStatus {
    AWARDED = 'AWARDED',
    CANCELLED = 'CANCELLED',
    IMPLEMENTED = 'IMPLEMENTED',
    INACTIVE = 'INACTIVE',
    IN_PROGRESS = 'IN_PROGRESS',
    PRICED = 'PRICED',
    REJECTED_CUSTOMER = 'REJECTED_CUSTOMER',
    REJECTED_PRICING = 'REJECTED_PRICING',
}

export enum LoggerType {
    CARTASENSE = 'CARTASENSE',
    CARTASENSE_M = 'CARTASENSE_M',
    CL_TT_7100 = 'CL_TT_7100',
    MR_810T = 'MR_810T',
    SKYCELL = 'SKYCELL',
    SKYCELL_SAVY_BLE = 'SKYCELL_SAVY_BLE',
    MR_812P = 'MR_812P',
    MR_813 = 'MR_813',
    MR_814 = 'MR_814',
    MR_815 = 'MR_815',
    CL_FRIGGA_V5_PLUS = 'CL_FRIGGA_V5_PLUS',
    MR_816 = 'MR_816',
}

export enum LoggerConfiguration {
    CF_AMBIENT = 'CF_AMBIENT',
    CF_BUTTON = 'CF_BUTTON',
    CF_INTERNAL = 'CF_INTERNAL',
    CF_TOP = 'CF_TOP',
    CF_BOTTOM = 'CF_BOTTOM',
    CF_LEFT = 'CF_LEFT',
    CF_RIGHT = 'CF_RIGHT',
    CF_BACK = 'CF_BACK',
    CF_DOOR = 'CF_DOOR',
    CF_UNSPECIFIED = 'CF_UNSPECIFIED',
    CF_SAVY_NOT_APPLICABLE = 'CF_SAVY_NOT_APPLICABLE',
    CF_MR_813_AMBIENT = 'CF_MR_813_AMBIENT',
    CF_MR_812P_AMBIENT= 'CF_MR_812P_AMBIENT',
}

export enum SensorCode {
    CONNECTOR_1 = 'CONNECTOR_1',
    CONNECTOR_2 = 'CONNECTOR_2',
    CONNECTOR_3 = 'CONNECTOR_3',
    CONNECTOR_4 = 'CONNECTOR_4',
    CONNECTOR_5 = 'CONNECTOR_5',
    CONNECTOR_6 = 'CONNECTOR_6',
    CONNECTOR_DOOR = 'CONNECTOR_DOOR',
    INTEGRATED = 'INTEGRATED',
    ON_BOARD_CONNECTOR = 'ON_BOARD_CONNECTOR',
    ONE_WIRE_1 = 'ONE_WIRE_1',
    ONE_WIRE_2 = 'ONE_WIRE_2',
    ONE_WIRE_3 = 'ONE_WIRE_3',
    ONE_WIRE_4 = 'ONE_WIRE_4',
    ONE_WIRE_5 = 'ONE_WIRE_5',
    ONE_WIRE_6 = 'ONE_WIRE_6',
    HUMIDITY = 'HUMIDITY',
}

export enum ContactMainType {
    BILLING = 'BILLING',
    CLIENT_SERVICES = 'CLIENT_SERVICES',
    OPERATIONS = 'OPERATIONS',
    PARTNER_MANAGEMENT = 'PARTNER_MANAGEMENT',
    SKYCELL = 'SKYCELL',
    TECHNICAL_SERVICES = 'TECHNICAL_SERVICES'
}

export enum LocationType {
    ADDRESS = 'ADDRESS',
    AIRPORT = 'AIRPORT',
    RAILPORT = 'RAILPORT',
    SEAPORT = 'SEAPORT'
}

export enum AddressCategory {
    AEB_FREIGHT_BILL_AND_AUDIT = 'AEB_FREIGHT_BILL_AND_AUDIT',
    ASSET_TRACKING = 'ASSET_TRACKING',
    AIRLINE ='AIRLINE',
    CAREOF = 'CAREOF',
    CONSIGNEE = 'CONSIGNEE',
    CONSOLIDATEDPOINT = 'CONSOLIDATEDPOINT',
    CUSTOMER = 'CUSTOMER',
    CUSTOMER_QUOTATION = 'CUSTOMER_QUOTATION',
    FORWARDER = 'FORWARDER',
    FORWARDERCUSTOMS = 'FORWARDERCUSTOMS',
    FORWARDEREXPORT = 'FORWARDEREXPORT',
    FORWARDERIMPORT = 'FORWARDERIMPORT',
    FORWARDERPOSITIONING = 'FORWARDERPOSITIONING',
    FORWARDERTRUCKING = 'FORWARDERTRUCKING',
    FWD_EXPORT_AIR = 'FWD_EXPORT_AIR',
    FWD_EXPORT_ROAD = 'FWD_EXPORT_ROAD',
    FWD_EXPORT_SEA = 'FWD_EXPORT_SEA',
    FWD_IMPORT_AIR = 'FWD_IMPORT_AIR',
    FWD_IMPORT_ROAD = 'FWD_IMPORT_ROAD',
    FWD_IMPORT_SEA = 'FWD_IMPORT_SEA',
    FWD_POSITIONING_AIR = 'FWD_POSITIONING_AIR',
    FWD_POSITIONING_ROAD = 'FWD_POSITIONING_ROAD',
    FWD_POSITIONING_SEA = 'FWD_POSITIONING_SEA',
    GATEWAYSTATION = 'GATEWAYSTATION',
    HANDOVERPOINT = 'HANDOVERPOINT',
    INSTITUTE = 'INSTITUTE',
    LSPWAREHOUSE = 'LSPWAREHOUSE',
    NOTIFYPARTY = 'NOTIFYPARTY',
    PHARMACOMPANY = 'PHARMACOMPANY',
    PHARMAWAREHOUSE = 'PHARMAWAREHOUSE',
    SERVICECENTER = 'SERVICECENTER',
    SERVICEPROVIDER = 'SERVICEPROVIDER',
    SUPPLIER = 'SUPPLIER',
}

export enum ContactType {
    SCAPPQM = 'SCAPPQM',
    PROVISION = 'PROVISION',
    SCAPPUSER = 'SCAPPUSER',
    CLAIMS_RESPONSIBLE = 'CLAIMS_RESPONSIBLE',
    NOTIFICATION_ORDER_MGMT = 'NOTIFICATION_ORDER_MGMT',
    OPS_DAILY = 'OPS_DAILY',
    OPS_PROCUREMENT = 'OPS_PROCUREMENT',
    OPS_BILLING = 'OPS_BILLING',
    OPS_PM = 'OPS_PM',
    OPS_POOLING = 'OPS_POOLING',
    PRICING = 'PRICING',
    ROAD_RESPONSIBLE = 'ROAD_RESPONSIBLE',
    AIREXPORT_RESPONSIBLE = 'AIREXPORT_RESPONSIBLE',
    SEAEXPORT_RESPONSIBLE = 'SEAEXPORT_RESPONSIBLE',
    AIRIMPORT_RESPONSIBLE = 'AIRIMPORT_RESPONSIBLE',
    SEAIMPORT_RESPONSIBLE = 'SEAIMPORT_RESPONSIBLE',
    RAIL_RESPONSIBLE = 'RAIL_RESPONSIBLE',
    SKYCELL = 'SKYCELL',
    SKYMIND_CUSTOMER = 'SKYMIND_CUSTOMER',
    SKYCELL_COMMERCIAL = 'SKYCELL_COMMERCIAL'
}

export enum NotificationTypes {
    FOLLOW_UP = 'FOLLOW_UP',
    NOTIFICATION = 'NOTIFICATION',
}

export enum NotificationRecipientType {
    CUSTOMER = 'CUSTOMER',
    CONSIGNEE = 'CONSIGNEE',
    EVENT_CONTACT = 'EVENT_CONTACT',
    FORWARDER_CUSTOMS = 'FORWARDER_CUSTOMS',
    FORWARDER_EXPORT = 'FORWARDER_EXPORT',
    FORWARDER_POSITIONING = 'FORWARDER_POSITIONING',
    FORWARDER_TRUCKING = 'FORWARDER_TRUCKING',
    FORWARDER_ROAD_INTERNAL_PICKUP = 'FORWARDER_ROAD_INTERNAL_PICKUP',
    FORWARDER_ROAD_INTERNAL_DELIVERY = 'FORWARDER_ROAD_INTERNAL_DELIVERY',
    FORWARDER_ROAD_INTERNAL = 'FORWARDER_ROAD_INTERNAL',
    FORWARDER_AIR_INTERNAL = 'FORWARDER_AIR_INTERNAL',
    FORWARDER_AIR_ROAD_INTERNAL = 'FORWARDER_AIR_ROAD_INTERNAL',
    FORWARDER_SEA_INTERNAL = 'FORWARDER_SEA_INTERNAL',
    FORWARDER_SEA_ROAD_INTERNAL = 'FORWARDER_SEA_ROAD_INTERNAL',
    HANDOVER_POINT = 'HANDOVER_POINT',
    ORIGIN_LOCATION = 'ORIGIN_LOCATION',
    DESTINATION_LOCATION = 'DESTINATION_LOCATION',
    SKYCELL_BILLING = 'SKYCELL_BILLING',
    SKYCELL_TECHNICALSERVICES = 'SKYCELL_TECHNICALSERVICES',
    SKYCELL_OPS = 'SKYCELL_OPS',
    ORIGIN_LOCATION_PROVISION = 'ORIGIN_LOCATION_PROVISION',
    DROPOFFPOINT = 'DROPOFFPOINT',
    EVENT_BOOKER = 'EVENT_BOOKER',
    SERVICE_CENTER_SKYCENTER_REPORT = 'SERVICE_CENTER_SKYCENTER_REPORT',
    SKYCELL_POOLING = 'SKYCELL_POOLING',
}

export enum ContainerVolume {
    VOLUME_1 = 'VOLUME_1',
    VOLUME_5 = 'VOLUME_5',
    VOLUME_40 = 'VOLUME_40',
    VOLUME_770 = 'VOLUME_770',
    VOLUME_1190 = 'VOLUME_1190',
    VOLUME_1500 = 'VOLUME_1500',
    VOLUME_2500 = 'VOLUME_2500',
    VOLUME_0_000_3 = 'VOLUME_0_000_3',
    VOLUME_0_008 = 'VOLUME_0_008',
    VOLUME_0_209 = 'VOLUME_0_209',
}

export enum ContainerCode {
    _000 = '_000',
    _002 = '_002',
    _011 = '_011',
    _012 = '_012',
    _013 = '_013',
    _031 = '_031',
    _032 = '_032',
    _021 = '_021',
    _022 = '_022',
    _024 = '_024',
    _051 = '_051',
    _052 = '_052',
    _101 = '_101',
    _102 = '_102',
    _103 = '_103',
    _104 = '_104',
    _105 = '_105',
    _106 = '_106',
    _107 = '_107',
    _108 = '_108',
    _109 = '_109',
    _110 = '_110',
    _111 = '_111',
    _801 = '_801',
    _802 = '_802',
    _803 = '_803',
    _804 = '_804',
    _805 = '_805',
    _806 = '_806',
    _807 = '_807',
    _808 = '_808',
    _809 = '_809',
    _810 = '_810',
    _999 = '_999'
}

export enum ContainerGeneration {
    G1 = 'G1',
    G2 = 'G2',
    G3 = 'G3',
    G4 = 'G4',
    G4_2 = 'G4.2',
    NOT_APPLICABLE = 'NOT_APPLICABLE'
}

export enum TemperatureRange {
    BT = 'BT',
    C = 'C',
    CRT = 'CRT',
    DF = 'DF',
    F = 'F',
    F_AND_DF = 'F_AND_DF',
    C_AND_CRT = 'C_AND_CRT',
    C_CRT_ERT = 'C_CRT_ERT',
    BT_C_CRT_F_DF = 'BT_C_CRT_F_DF',
    UNDEFINED = 'UNDEFINED'
}

export enum OrderTemperatureRange {
    C = 'C',
    CRT = 'CRT',
    F = 'F',
    DF = 'DF',
    BT = 'BT',
    ANY_TEMP_RANGE = 'ANY_TEMP_RANGE',
}

export enum LaneGroupTemperatureRange {
    C = 'C',
    CRT = 'CRT',
    F = 'F',
    DF = 'DF',
}

export enum TemperatureRangeAll {
    BT = 'BT',
    C = 'C',
    CRT = 'CRT',
    DF = 'DF',
    DRY = 'DRY',
    ERT = 'ERT',
    F = 'F',
}

export enum DefaultTemperatureRange {
    BT = 'BT',
    C = 'C',
    CRT = 'CRT',
    DF = 'DF',
    F = 'F',
    ERT = 'ERT'
}

export enum TemperatureRangePreconditioned {
    BT = 'BT',
    C = 'C',
    CRT = 'CRT',
    DF = 'DF',
    F = 'F',
    ERT = 'ERT'
}

export enum AdditionalFeesStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    VALID = 'VALID',
    CANCELLED = 'CANCELLED'
}

export enum ContainerStatus {
    BOOKED_TO_ORDER = 'BOOKED_TO_ORDER',
    CHECK_PRECONDITIONING = 'CHECK_PRECONDITIONING',
    CHECK_STATUS = 'CHECK_STATUS',
    CLEANED = 'CLEANED',
    EVENT = 'EVENT',
    ICED = 'ICED',
    IN_PRODUCTION = 'IN_PRODUCTION',
    IN_TESTING = 'IN_TESTING',
    LOST = 'LOST',
    OK = 'OK',
    ORDER_CLOSED = 'ORDER_CLOSED',
    PERFORMANCE_FAILED_INSULATION = 'PERFORMANCE_FAILED_INSULATION',
    PERFORMANCE_FAILED_PCM = 'PERFORMANCE_FAILED_PCM',
    POSITIONING = 'POSITIONING',
    PRECONDITIONED = 'PRECONDITIONED',
    PRECONDITIONING = 'PRECONDITIONING',
    PRECON_FAILED_DATA = 'PRECON_FAILED_DATA',
    PRECON_FAILED_TEMPERATURE = 'PRECON_FAILED_TEMPERATURE',
    PRE_APPROVED = 'PRE_APPROVED',
    PRE_SELECTED = 'PRE_SELECTED',
    QC_FAILED_DATA = 'QC_FAILED_DATA',
    QC_FAILED_PERFORMANCE = 'QC_FAILED_PERFORMANCE',
    QC_FAILED_TEMPERATURE = 'QC_FAILED_TEMPERATURE',
    QC_PASSED = 'QC_PASSED',
    QC_PASSED_AUTOMATED = 'QC_PASSED_AUTOMATED',
    READY_TO_SHIP = 'READY_TO_SHIP',
    RECYCLED = 'RECYCLED',
    RELEASED_FROM_ORDER = 'RELEASED_FROM_ORDER',
    RELEASED_FROM_PRODUCTION = 'RELEASED_FROM_PRODUCTION',
    RETURNED_DAMAGED = 'RETURNED_DAMAGED',
    RETURNED_OK = 'RETURNED_OK',
    SCRAPPED = 'SCRAPPED',
    TO_BE_MAINTAINED = 'TO_BE_MAINTAINED',
    TO_BE_OVERHAULED = 'TO_BE_OVERHAULED',
    TO_BE_PROVISIONED = 'TO_BE_PROVISIONED',
    TO_BE_RECYCLED = 'TO_BE_RECYCLED',
    TO_BE_REPAIRED = 'TO_BE_REPAIRED',
    TO_BE_ICED = 'TO_BE_ICED',
    TO_BE_LOGGER_EXCHANGED = 'TO_BE_LOGGER_EXCHANGED',
    TO_BE_PRECONDITIONED = 'TO_BE_PRECONDITIONED',
    TO_BE_RECEIVED = 'TO_BE_RECEIVED',
    TO_BE_RECONDITIONED = 'TO_BE_RECONDITIONED',
    TRANSPORT = 'TRANSPORT',
    TS_APPROVED = 'TS_APPROVED',
    UNCHECKED = 'UNCHECKED',
    UNUSED = 'UNUSED',
}

export enum ContainerProcess {
    RECEPTION = 'RECEPTION',
    INSPECTION = 'INSPECTION',
    CLEANING = 'CLEANING',
    PRECONDITIONING_START = 'PRECONDITIONING_START',
    PRECONDITIONING_END = 'PRECONDITIONING_END',
    ICING = 'ICING',
    PROVISION = 'PROVISION',
    MAINTENANCE = 'MAINTENANCE',
    REPAIR = 'REPAIR',
    OVERHAUL = 'OVERHAUL',
    ASSEMBLY = 'ASSEMBLY',
    LOGGER_EXCHANGE = 'LOGGER_EXCHANGE',
}

export enum LaneFeesStatus {
    AWARDED = 'AWARDED',
    CANCELLED = 'CANCELLED',
    IN_PROGRESS = 'IN_PROGRESS',
    REJECTED = 'REJECTED',
    SUBMITTED = 'SUBMITTED'
}
export enum PricingStatus {
    CANCELLED = 'CANCELLED',
    VALID = 'VALID',
    IN_PROGRESS = 'IN_PROGRESS',
}

export enum Frequency {
    DAILY = 'DAILY',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    ANNUALLY = 'ANNUALLY'
}

export enum StepTypeDetailed {
    ROAD_DELIVERY = 'ROAD_DELIVERY',
    ROAD_PICKUP = 'ROAD_PICKUP',
    ROAD_TRACKING = 'ROAD_TRACKING',
    ROAD_INTERNAL = 'ROAD_INTERNAL',
    ROAD_INTERNAL_PICKUP = 'ROAD_INTERNAL_PICKUP',
    ROAD_INTERNAL_DELIVERY = 'ROAD_INTERNAL_DELIVERY',
    AIR_COLLECTION = 'AIR_COLLECTION',
    AIR_TRACKING = 'AIR_TRACKING',
    AIR_INTERNAL = 'AIR_INTERNAL',
    AIR_ROAD_INTERNAL = 'AIR_ROAD_INTERNAL',
    SEA_INTERNAL = 'SEA_INTERNAL',
    SEA_ROAD_INTERNAL = 'SEA_ROAD_INTERNAL',
    UNLOADING_CONFIRM = 'UNLOADING_CONFIRM',
    DROPOFF_CONFIRM = 'DROPOFF_CONFIRM',
    EVENT_EVENT = 'EVENT_EVENT',
    ROAD_TO_EVENT = 'ROAD_TO_EVENT',
    ROAD_FROM_EVENT = 'ROAD_FROM_EVENT',
    CUSTOMS_CUSTOMS = 'CUSTOMS_CUSTOMS',
    RAIL_INTERNAL = 'RAIL_INTERNAL'
}

export enum FreightTypes {
    CONTAINERS = 'CONTAINERS',
    GENERAL_FREIGHT = 'GENERAL_FREIGHT',
}

export enum OrderStatuses {
    NOT_STARTED = 'NOT_STARTED',
    SELECTED = 'SELECTED',
    BOOKED = 'BOOKED',
    TRANSPORT = 'TRANSPORT',
    CLOSED = 'CLOSED',
    CANCELLED = 'CANCELLED',
    WAITING_FOR_POOLING = 'WAITING_FOR_POOLING',
    CONFIRMING_PRECON_FEASIBILITY = 'CONFIRMING_PRECON_FEASIBILITY',
    CONFIRMED = 'CONFIRMED',
    IN_DISCUSSION = 'IN_DISCUSSION',
    REJECTED = 'REJECTED',
    TO_BE_PROVISIONED = 'TO_BE_PROVISIONED',
    READY_TO_SHIP = 'READY_TO_SHIP',
    POSITIONING = 'POSITIONING',
    RESERVED = 'RESERVED',
}

export enum ContainerNoteType {
    CONTAINER_LOCATION_CHANGE = 'CONTAINER_LOCATION_CHANGE',
    CONTAINER_STATUS_CHANGE = 'CONTAINER_STATUS_CHANGE',
    GENERAL_STATUS_CHANGE = 'GENERAL_STATUS_CHANGE',
    LOGGER_EXCHANGE = 'LOGGER_EXCHANGE',
    LOGGER_REMOVAL = 'LOGGER_REMOVAL',
    NOTE = 'NOTE'
}

export enum PaymentTerms {
    PAYMENT_TERMS_0_DAYS = 'PAYMENT_TERMS_0_DAYS',
    PAYMENT_TERMS_30_DAYS = 'PAYMENT_TERMS_30_DAYS',
    PAYMENT_TERMS_45_DAYS = 'PAYMENT_TERMS_45_DAYS',
    PAYMENT_TERMS_60_DAYS = 'PAYMENT_TERMS_60_DAYS',
}

export enum InvoiceDesignation {
    BASELEASE = 'BASELEASE',
    CANCELLATION = 'CANCELLATION',
    CREDIT_NOTE = 'CREDIT_NOTE',
    DAMAGE = 'DAMAGE',
    EXTRADAYS = 'EXTRADAYS',
    LOSS = 'LOSS',
}

export enum SubstepName {
    REQUESTED_PICKUP = 'REQUESTED_PICKUP',
    DRIVER_DETAILS = 'DRIVER_DETAILS',
    ACTUAL_PICKUP = 'ACTUAL_PICKUP',
    ADD_INFO = 'ADD_INFO',
    ADD_PICKUP = 'ADD_PICKUP',
    CONFIRMATION = 'CONFIRMATION',
    CLOSED = 'CLOSED',
    COLLECTED = 'COLLECTED',
    CREATION = 'CREATION',
    PICKED_UP = 'PICKED_UP',
    IN_FLIGHT = 'IN_FLIGHT',
    DEPARTURE = 'DEPARTURE',
    ARRIVAL = 'ARRIVAL',
    ENTERED = 'ENTERED',
    CONFIRMED = 'CONFIRMED',
    EVENT = 'EVENT'
}

export enum SimulationProfile {
    WINTER = 'WINTER',
    SUMMER = 'SUMMER'
}

export enum DamageStatus {
    REPORTED = 'REPORTED',
    CANCELLED = 'CANCELLED',
    FIXED = 'FIXED'
}

export enum ReportingDepartment {
    TECHNICAL_SERVICES = 'TECHNICAL_SERVICES',
    OPERATIONS = 'OPERATIONS',
    PRODUCTION = 'PRODUCTION',
    OTHER = 'OTHER'
}

export enum RegionZeroLevel {
    AMERICAS = 'AMERICAS',
    EMEA = 'EMEA',
    APAC = 'APAC',
}
export enum RegionFirstLevel {
    AMERICA_NORTH = 'AMERICA_NORTH',
    AMERICA_SOUTH = 'AMERICA_SOUTH',
    EUROPE = 'EUROPE',
    AFRICA = 'AFRICA',
    MIDDLE_EAST = 'MIDDLE_EAST',
    ASIA_SOUTH = 'ASIA_SOUTH',
    ASIA_SOUTHEAST = 'ASIA_SOUTHEAST',
    ASIA_EAST = 'ASIA_EAST',
    OCEANIA = 'OCEANIA',
    REST_OF_THE_WORLD = 'REST_OF_THE_WORLD',
}
export enum RegionSecondLevel {
    MEXICO = 'MEXICO',
    PUERTO_RICO = 'PUERTO_RICO',
    AMERICA_CENTRAL_REST = 'AMERICA_CENTRAL_REST',
    CANADA_WEST = 'CANADA_WEST',
    CANADA_MID = 'CANADA_MID',
    CANADA_EAST = 'CANADA_EAST',
    US_MIDWEST = 'US_MIDWEST',
    US_NORTHEAST = 'US_NORTHEAST',
    US_SOUTHWEST = 'US_SOUTHWEST',
    US_SOUTHEAST = 'US_SOUTHEAST',
    US_WEST = 'US_WEST',
    BRAZIL = 'BRAZIL',
    ARGENTINA = 'ARGENTINA',
    AMERICA_SOUTH_REST = 'AMERICA_SOUTH_REST',
    EUROPE_CENTRAL = 'EUROPE_CENTRAL',
    EUROPE_EAST = 'EUROPE_EAST',
    EUROPE_SOUTH = 'EUROPE_SOUTH',
    SCANDINAVIA = 'SCANDINAVIA',
    UNITED_KINGDOM = 'UNITED_KINGDOM',
    IRELAND = 'IRELAND',
    EGYPT = 'EGYPT',
    KENIA = 'KENIA',
    SOUTH_AFRICA = 'SOUTH_AFRICA',
    AFRICA_REST = 'AFRICA_REST',
    ISRAEL = 'ISRAEL',
    MIDDLE_EAST_REST = 'MIDDLE_EAST_REST',
    INDIA = 'INDIA',
    ASIA_SOUTH_REST = 'ASIA_SOUTH_REST',
    SINGAPORE = 'SINGAPORE',
    ASIA_SOUTHEAST_REST = 'ASIA_SOUTHEAST_REST',
    CHINA_NORTH = 'CHINA_NORTH',
    CHINA_SOUTH = 'CHINA_SOUTH',
    JAPAN = 'JAPAN',
    SOUTH_KOREA = 'SOUTH_KOREA',
    AUSTRALIA_WEST = 'AUSTRALIA_WEST',
    AUSTRALIA_CENTRAL = 'AUSTRALIA_CENTRAL',
    AUSTRALIA_EAST = 'AUSTRALIA_EAST',
    NEW_ZEALAND = 'NEW_ZEALAND',
    REST_OF_THE_WORLD = 'REST_OF_THE_WORLD',
}

export enum OpportunityFrequency {
    ANNUAL = 'ANNUAL',
    ONE_OFF = 'ONE OFF',
    SEASONAL = 'SEASONAL',
}

export enum OpportunityPalletType {
    EU = 'EU',
    LOOSE_LOAD = 'LOOSE_LOAD',
    OTHER = 'OTHER',
    UNKNOWN = 'UNKNOWN',
    US = 'US',
}

export enum OpportunityStatus {
    OPEN = 'OPEN',
    LOST = 'LOST',
    WON = 'WON',
}

export enum paymentSchedule {
    PAYMENT_SCHEDULE_DAILY = 'PAYMENT_SCHEDULE_DAILY',
    PAYMENT_SCHEDULE_WEEKLY = 'PAYMENT_SCHEDULE_WEEKLY',
    PAYMENT_SCHEDULE_BIWEEKLY = 'PAYMENT_SCHEDULE_BIWEEKLY',
    PAYMENT_SCHEDULE_10DAYS = 'PAYMENT_SCHEDULE_10DAYS',
    PAYMENT_SCHEDULE_MONTHLY = 'PAYMENT_SCHEDULE_MONTHLY',
}

export enum operationalDays {
    MONDAY_FRIDAY = 'MONDAY_FRIDAY',
    MONDAY_SUNDAY = 'MONDAY_SUNDAY',
    SUNDAY_THURSDAY = 'SUNDAY_THURSDAY',
}
