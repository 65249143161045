import React from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import Typography from '@mui/material/Typography'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import Card from 'app/shared-components/Card'
import {
    OrderStep,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    StepTypeDetailed,
} from 'app/types/enums'

import getInternalAirFormFields from './fields/stepAirForm.fields'
import getInternalRoadFormFields from './fields/stepRoadForm.fields'
import getInternalSeaFormFields from './fields/stepSeaForm.fields'
import useStyles from './OrderStepOption.style'

const FIELDS = {
    ROAD: getInternalRoadFormFields,
    SEA: getInternalSeaFormFields,
    AIR: getInternalAirFormFields,
}

const OrderStepOption = ({
    value,
    onChange,
    freightRateServiceProviderCode,
    countryName,
    zip,
}: {
    value: Partial<OrderStep>,
    onChange: (newVal: Partial<OrderStep>) => void,
    freightRateServiceProviderCode: string,
    countryName: string,
    zip: string,
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const allowedValuesStepTypeDetailed = useEnumValues()(StepTypeDetailed)

    const {
        stepType,
        transportModeRoad,
        transportModeForwarder,
        forwarder,
        pickupLocation,
        deliveryLocation,
        location,
        transportModeSea,
        stepTypeDetailed,
    } = value

    const fieldsWithClassName = useFieldsWithClassName(FIELDS[stepType]({
        locationId: location?.id,
        forwarderId: forwarder?.id,
        pickupLocationId: pickupLocation?.id,
        deliveryLocationId: deliveryLocation?.id,
        transportModeSea,
        transportModeForwarder,
        transportModeRoad,
        getAllowedValues,
        isRoadDelivery: stepTypeDetailed === StepTypeDetailed.ROAD_DELIVERY,
        freightRateServiceProviderCode,
        countryName,
        zip,
    }), classes)

    return (
        <Card className={classes.root}>
            <Typography
                className={classes.title}
                variant="h3"
            >
                <img
                    className={classes.iconTitle}
                    src={`assets/images/orderSteps/${stepType?.toLowerCase()}.svg`}
                    alt={stepTypeDetailed}
                />
                {allowedValuesStepTypeDetailed[stepTypeDetailed]}
            </Typography>
            <DomainObjectForm
                name="OrderStep"
                className={classes.gridWrapper}
                value={value}
                fields={fieldsWithClassName}
                onChange={onChange}
                wrapper="simple"
            />
        </Card>
    )
}

export default OrderStepOption
