import {
    createContext, useContext,
} from 'react'

import {
    StepperContextType,
} from 'app/types/common.types'

import {
    LongTermLeaseType,
} from '../longTermLease.types'

export type LongTermLeaseFormContextType = StepperContextType<{
    firstStep: Partial<LongTermLeaseType>,
    secondStep: Partial<LongTermLeaseType>,
    thirdStep: Partial<LongTermLeaseType>,
}>

export const LongTermLeaseCreateContext = createContext<LongTermLeaseFormContextType>(null)

const useLongTermLeaseCreateContext = () => {
    return useContext<LongTermLeaseFormContextType>(LongTermLeaseCreateContext)
}

export default useLongTermLeaseCreateContext
