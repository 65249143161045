import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    ProductType,
} from 'app/types/enums'

import {
    LongTermLeaseType,
} from '../../../longTermLease.types'

const getFields = (
    allowedProductTypes: ProductType[],
): DTOCardFormField<keyof LongTermLeaseType>[] => {
    return [{
        title: 'Container Details',
        className: 'containerDetails',
        id: 'containerDetails',
        fields: [{
            name: 'requiredContainers',
            componentName: 'RequiredContainerByProductTypeList',
            allowedProductTypes,
            required: true,
            labelKey: 'Prebooking.requiredContainers',
        }],
    }]
}

export default getFields
