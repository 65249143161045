import {
    SetStateAction, Dispatch,
    useContext, createContext,
} from 'react'

export type SkyNetConfirmationContextType = {
    setAction: Dispatch<SetStateAction<(...a: any[]) => void>>,
    setOpen: Dispatch<SetStateAction<boolean>>
}

export const SkyNetConfirmationContext = createContext<SkyNetConfirmationContextType>(null)

const useSkyNetConfirmationContext = () => {
    return useContext<SkyNetConfirmationContextType>(SkyNetConfirmationContext)
}

export default useSkyNetConfirmationContext
