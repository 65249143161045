import React, {
    useEffect,
    useMemo,
    useRef,
} from 'react'
import moment from 'moment-business-days'

import useEnumValues from 'app/hooks/useEnumValues'
import Button from 'app/shared-components/Button'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import Tooltip from 'app/shared-components/Tooltip'
import validateForm from 'app/utils/validateForm'
import useGetLocation from 'app/Apps/ContactManagement/Locations/hooks/useGetLocation'
import replaceTimezone from 'app/utils/date/replaceTimezone'

import {
    AutomaticallyFormType,
} from '../../createAutomaticallyForm.types'
import getFields from './orderInformation.fields'
import useStyles from './OrderInformation.style'

const OrderInformation = ({
    value,
    onChange,
    activeStep,
    setActiveStep,
    index,
}: {
    value: AutomaticallyFormType,
    onChange: (newVal: AutomaticallyFormType) => void,
    activeStep: number,
    setActiveStep: (val: number) => void,
    index: number,
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const {
        originLocation,
        temperatureRange,
        selectPreviousOrders,
        requiredContainers,
        destinationSelection,
    } = value

    const selectedLocation = useRef(undefined)
    const selectedTemperatureRange = useRef(temperatureRange)

    const {
        data: location,
    } = useGetLocation(originLocation?.id)

    useEffect(() => {
        if (selectedTemperatureRange.current !== temperatureRange && requiredContainers) {
            selectedTemperatureRange.current = temperatureRange
            onChange({
                ...value,
                requiredContainers: null,
            })
        }
    }, [
        onChange,
        requiredContainers,
        temperatureRange,
        value,
    ])

    useEffect(() => {
        if ((location !== selectedLocation.current) && location.timeZone) {
            selectedLocation.current = location
            onChange({
                ...value,
                requestedPickup: moment()
                    .businessAdd(2)
                    .utcOffset(replaceTimezone(location.timeZone))
                    .set({
                        hour: 10,
                        minutes: 0,
                    })
                    .format('DD.MM.YYYY HH:mm ZZ'),
            })
        }
    }, [
        location,
        onChange,
        value,
    ])

    const fields = useFieldsWithClassName(getFields({
        originLocation,
        temperatureRange,
        selectPreviousOrders,
        getAllowedValues,
        destinationSelection,
        disabled: activeStep > index,
    }), classes)

    const onclickEdit = () => {
        onChange({
            ...value,
            bestOption: null,
            orderSteps: null,
        })
        setActiveStep(index)
    }

    const isFormValid: boolean = useMemo(() => {
        return validateForm({
            fields,
            value,
        })
    }, [
        fields,
        value,
    ])

    return (
        <>
            <DomainObjectForm
                value={value}
                onChange={onChange}
                fields={fields}
                classNames={{
                    gridCardWrapper: classes.gridCardWrapper,
                }}
                disabled={activeStep > index}
                name="OrderInformation"
            />
            <div className={classes.actions}>
                {activeStep === index && (
                    <Tooltip
                        title="Please fill in all mandatory fields to continue"
                        disabled={isFormValid}
                    >
                        <div>
                            <Button
                                label="Continue"
                                onClick={() => { setActiveStep(index + 1) }}
                                disabled={!isFormValid}
                                data-testid="btn-continue"
                            />
                        </div>
                    </Tooltip>
                )}
                {activeStep > index && (
                    <Button
                        label="Edit"
                        onClick={onclickEdit}
                        className={classes.button}
                        data-testid="btn-edit"
                    />
                )}
            </div>
        </>
    )
}

export default OrderInformation
