import React, {
    useCallback, useMemo,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import useValidation from 'app/hooks/useValidation/useValidation'
import useEnumValues from 'app/hooks/useEnumValues'

import useLongTermLeaseCreateContext from '../../LongTermLeaseCreateContext'
import {
    LongTermLeaseType,
} from '../../../longTermLease.types'
import getFields from './bookingDetails.fields'

const BookingDetails = ({
    disabled,
    index,
}: {
    disabled: boolean,
    index: number
}) => {
    const getAllowedValues = useEnumValues()

    const {
        activeStep,
        setActiveStep,
        setValue,
        value,
    } = useLongTermLeaseCreateContext()

    const fields = useMemo(() => {
        return getFields(getAllowedValues)
    }, [getAllowedValues])

    const {
        isValid,
    } = useValidation({
        fields,
        values: value.secondStep,
    })

    const onChange = (newVal: Partial<LongTermLeaseType>) => {
        setValue({
            ...value,
            secondStep: newVal,
        })
    }

    const handleNextAction = useCallback(() => {
        setActiveStep(activeStep + 1)
    }, [
        setActiveStep,
        activeStep,
    ])

    const backAction = useCallback(() => {
        setActiveStep(activeStep - 1)
        setValue({
            ...value,
            secondStep: {},
            thirdStep: {},
        })
    }, [
        setActiveStep,
        activeStep,
        setValue,
        value,
    ])

    const editAction = useCallback(() => {
        setActiveStep(index)
        setValue({
            ...value,
            thirdStep: {},
        })
    }, [
        index,
        setActiveStep,
        setValue,
        value,
    ])

    return (
        <>
            <DomainObjectForm
                value={value.secondStep}
                onChange={onChange}
                fields={fields}
                disabled={disabled}
                name="OrderByNumberView"
            />
            <SkyNetStepperButtons
                isValid={isValid}
                disabled={disabled}
                nextLabel="Continue"
                nextAction={handleNextAction}
                canGoBack
                backAction={backAction}
                canEdit={activeStep > index}
                editAction={editAction}
            />
        </>
    )
}

export default BookingDetails
