import React from 'react'

const defaultProps = {
    value: [],
    delimiter: ', ',
}

export default function TableMultipleValues({
    value,
    delimiter,
}: Readonly<{
    value?: string[],
    delimiter?: string,
}>) {
    return (
        <div>{value?.join(delimiter) || null}</div>
    )
}

TableMultipleValues.defaultProps = defaultProps
