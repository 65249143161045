import {
    AddressCategory,
    LaneGroupTemperatureRange, LeaseType, LocationType,
} from 'app/types/enums'
import {
    DTOCardFormField, FieldInCard, NestedField, Validate,
} from 'app/types/form.types'
import {
    DeliveryServiceType, TransportModeRoad, CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    AccountLaneForm,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'
import {
    Contact,
} from 'app/Apps/ContactManagement/Contacts/Contacts.types'

const getFields = ({
    getAllowedValues,
    showFTL,
    showLTL,
    isDeliveryRoad,
    isCollection,
    isCollectonOrDropOffForwarder,
    hasAwardedLanes,
    contractBasisAdminPermission,
    handoverPointValidation,
    collectionDropoffPointValidation,
    handoverPointContactsValidate,
    collectionPointContactValidate,
    getContactManagementLink,
    getEditHandoverAddressLink,
    getEditCollectionAddressLink,
}: {
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    showFTL: boolean,
    showLTL: boolean,
    isDeliveryRoad: boolean,
    isCollection: boolean,
    isCollectonOrDropOffForwarder: boolean,
    hasAwardedLanes: boolean,
    contractBasisAdminPermission: boolean,
    handoverPointValidation: Validate<Location>,
    collectionDropoffPointValidation: Validate<Location>,
    handoverPointContactsValidate: Validate<Contact[]> | Validate<Contact[]>[],
    collectionPointContactValidate: Validate<Contact[]> | Validate<Contact[]>[],
    getContactManagementLink?: () => JSX.Element[],
    getEditHandoverAddressLink?: () => JSX.Element[],
    getEditCollectionAddressLink?: () => JSX.Element[],
}): DTOCardFormField <AccountLaneForm>[] => {
    const generalFields: FieldInCard<AccountLaneForm>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'account',
                    labelKey: 'AccountLane.accountCompanyName',
                    componentName: 'AccountSelector',
                    disabled: true,
                },
                {
                    name: 'contractBasisAccount',
                    componentName: 'AccountSelector',
                    labelKey: 'Prebooking.pricingAccount',
                    disabled: true,
                },
                {
                    name: 'temperatureRange',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                    labelKey: 'AccountLane.temperatureRange',
                    disabled: true,
                },
                {
                    name: 'leaseType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LeaseType),
                    labelKey: 'AccountLane.leaseType',
                    required: true,
                    showInColumns: true,
                    disabled: true,
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'laneComment',
                    componentName: 'LongText',
                    labelKey: 'AccountLane.laneComment',
                    disabled: true,
                },
                {
                    name: 'changedOn',
                    componentName: 'DateTimeSelect',
                    labelKey: 'AccountLane.changedOn',
                    disabled: true,
                },
                {
                    name: 'changedBy',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.changedBy',
                    disabled: true,
                },
                {
                    name: 'laneGroupTag',
                    labelKey: 'LaneGroup.laneGroupTag',
                    componentName: 'RestrictedPermissionInputSingleline',
                    hasPermission: contractBasisAdminPermission,
                    text: 'You do not have permission to view this field.',
                    disabled: true,
                },
            ],
        },
    ]

    const otherFields: FieldInCard<AccountLaneForm>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'customerExecution',
                    labelKey: 'AccountLane.customerExecution',
                    componentName: 'LocationObjectSelector',
                    filterLocationType: LocationType.ADDRESS,
                    filterAddressCategory: [
                        AddressCategory.CUSTOMER,
                        AddressCategory.CUSTOMER_QUOTATION,
                    ],
                    required: true,
                },
                {
                    name: 'customerExecutionContactsQuote',
                    labelKey: 'AccountLane.customerExecutionContactsQuote',
                    componentName: 'MultipleContactObjectSelector',
                },
                {
                    name: 'consignee',
                    labelKey: 'AccountLane.consignee',
                    componentName: 'LocationObjectSelector',
                    filterLocationType: LocationType.ADDRESS,
                    required: isCollection,
                },
                {
                    name: 'consigneeContacts',
                    labelKey: 'LaneUpdateNewDto.consigneeContacts',
                    componentName: 'MultipleContactObjectSelector',
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'billingCompany',
                    labelKey: 'LaneUpdateNewDto.billingCompany',
                    componentName: 'BillingCompanyExpandedViewSelector',
                    required: true,
                },
                {
                    name: 'customerSite',
                    labelKey: 'LaneUpdateNewDto.customerSite',
                    componentName: 'LocationObjectSelector',
                    required: true,
                },
            ],
        },
    ]

    const transportModeRoad = {
        name: 'transportModeRoad',
        labelKey: 'LaneGroupCreateOrDuplicateDto.transportModeRoad',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        showInColumns: true,
        required: true,
        disabled: true,
    } as FieldInCard<AccountLaneForm>

    const isFtlPositioningTemperatureControlled = {
        name: 'isFtlPositioningTemperatureControlled',
        labelKey: 'AccountLane.isFtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
        disabled: true,
    } as FieldInCard<AccountLaneForm>

    const isLtlPositioningTemperatureControlled = {
        name: 'isLtlPositioningTemperatureControlled',
        labelKey: 'AccountLane.isLtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
        disabled: true,
    } as FieldInCard<AccountLaneForm>

    let transportModeFields = [transportModeRoad]

    if (showLTL) {
        transportModeFields = [
            ...transportModeFields,
            isLtlPositioningTemperatureControlled,
        ]
    }

    if (showFTL) {
        transportModeFields = [
            ...transportModeFields,
            isFtlPositioningTemperatureControlled,
        ]
    }

    const handoverFields: FieldInCard<AccountLaneForm>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'deliveryServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DeliveryServiceType),
                    labelKey: 'AccountLane.deliveryServiceType',
                    required: true,
                    disabled: true,
                },
                ...(isDeliveryRoad ? transportModeFields : []) as NestedField<AccountLaneForm>[],
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'handoverPoint',
                    componentName: 'LocationExpandedViewObjectSelector',
                    labelKey: 'LaneGroup.handoverPoint',
                    required: true,
                    includeFilters: {
                        isProspect: [
                            true,
                            false,
                        ],
                    },
                    validate: handoverPointValidation,
                    disabled: hasAwardedLanes,
                },
                {
                    name: 'handoverPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'AccountLane.handoverPointContacts',
                    required: isDeliveryRoad,
                    validate: handoverPointContactsValidate,
                },
            ],
        },
    ]

    const collectionFields: FieldInCard<AccountLaneForm>[] = [
        {
            id: 'column1',
            fields: [{
                name: 'collectionServiceType',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(CollServiceType),
                labelKey: 'AccountLane.collectionServiceType',
                required: true,
                disabled: true,
            }],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'collectionDropoffPoint',
                    componentName: 'LocationExpandedViewObjectSelector',
                    labelKey: 'LaneGroup.collectionDropoffPoint',
                    required: true,
                    includeFilters: {
                        isProspect: [
                            true,
                            false,
                        ],
                    },
                    validate: collectionDropoffPointValidation,
                    disabled: hasAwardedLanes,
                },
                {
                    name: 'collectionDropoffPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'AccountLane.collectionDropoffPointContacts',
                    required: isCollectonOrDropOffForwarder,
                    validate: collectionPointContactValidate,
                },
            ],
        },
    ]

    return [
        {
            id: 'general',
            title: 'General',
            className: 'general',
            fields: generalFields,
        },
        {
            id: 'other',
            title: 'Other',
            className: 'other',
            fields: otherFields,
            getTitlePanelElements: getContactManagementLink,
        },
        {
            id: 'handover',
            title: 'Handover',
            className: 'handover',
            fields: handoverFields,
            getTitlePanelElements: getEditHandoverAddressLink,
        },
        {
            id: 'collection',
            title: 'Collection',
            className: 'collection',
            fields: collectionFields,
            getTitlePanelElements: getEditCollectionAddressLink,
        },
    ]
}

export default getFields
