import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from 'app/Auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from '../longTermLease.request'
import {
    LongTermLeaseType,
} from '../longTermLease.types'

const useLongTermLeaseCreate = (onSuccess: ({
    id: number,
}) => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessLongTermLeaseCreate,
        onError,
    } = useHandleStatusRequest({
        title: 'Long-Term Lease was created',
        id: 'LongTermLease',
        onSuccess,
    })

    return useMutation({
        mutationFn: (longTermLease: LongTermLeaseType) => {
            return simpleApi({
                ...requests.Create({
                    data: longTermLease,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessLongTermLeaseCreate,
    })
}

export default useLongTermLeaseCreate
