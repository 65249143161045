import {
    LaneGroupTemperatureRange, LeaseType,
} from 'app/types/enums'
import {
    DTOCardFormField, FieldInCard, NestedField,
} from 'app/types/form.types'
import {
    DeliveryServiceType, TransportModeRoad, CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    AccountLaneForm,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import {
    AccountLaneFormFieldsArgs,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLaneForm/AccountLaneForm'

const getFields = ({
    getAllowedValues,
    showFTL,
    showLTL,
    isDeliveryRoad,
    isCollection,
    contractBasisAdminPermission,
    getAddressCreationLink,
}: Partial<AccountLaneFormFieldsArgs>): DTOCardFormField <AccountLaneForm>[] => {
    const generalFields: FieldInCard<AccountLaneForm>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'temperatureRange',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                    labelKey: 'AccountLane.temperatureRange',
                    disabled: true,
                },
                {
                    name: 'leaseType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LeaseType),
                    labelKey: 'AccountLane.leaseType',
                    required: true,
                    showInColumns: true,
                    disabled: true,
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'laneComment',
                    componentName: 'LongText',
                    labelKey: 'AccountLane.laneComment',
                    disabled: true,
                },
                {
                    name: 'changedOn',
                    componentName: 'DateTimeSelect',
                    labelKey: 'AccountLane.changedOn',
                    disabled: true,
                },
                {
                    name: 'changedBy',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.changedBy',
                    disabled: true,
                },
                {
                    name: 'laneGroupTag',
                    labelKey: 'LaneGroup.laneGroupTag',
                    componentName: 'RestrictedPermissionInputSingleline',
                    hasPermission: contractBasisAdminPermission,
                    text: 'You do not have permission to view this field.',
                },
            ],
        },
    ]

    const transportModeRoad = {
        name: 'transportModeRoad',
        labelKey: 'LaneGroupCreateOrDuplicateDto.transportModeRoad',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        showInColumns: true,
        required: true,
    } as FieldInCard<AccountLaneForm>

    const isFtlPositioningTemperatureControlled = {
        name: 'isFtlPositioningTemperatureControlled',
        labelKey: 'AccountLane.isFtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
    } as FieldInCard<AccountLaneForm>

    const isLtlPositioningTemperatureControlled = {
        name: 'isLtlPositioningTemperatureControlled',
        labelKey: 'AccountLane.isLtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
    } as FieldInCard<AccountLaneForm>

    let transportModeFields = [transportModeRoad]

    if (showLTL) {
        transportModeFields = [
            ...transportModeFields,
            isLtlPositioningTemperatureControlled,
        ]
    }

    if (showFTL) {
        transportModeFields = [
            ...transportModeFields,
            isFtlPositioningTemperatureControlled,
        ]
    }

    const handoverFields: FieldInCard<AccountLaneForm>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'deliveryServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DeliveryServiceType),
                    labelKey: 'AccountLane.deliveryServiceType',
                    disabled: true,
                },
                ...(isDeliveryRoad ? transportModeFields : []) as NestedField<AccountLaneForm>[],
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'handoverPoint',
                    componentName: 'LocationExpandedViewObjectSelector',
                    labelKey: 'LaneGroup.handoverPoint',
                    required: true,
                    includeFilters: {
                        isProspect: [
                            true,
                            false,
                        ],
                    },
                },
                ...(isDeliveryRoad ? [
                    {
                        name: 'handoverPointZipCode',
                        componentName: 'InputSingleline',
                        labelKey: 'AccountLane.handoverPointZipCode',
                        required: true,
                        disabled: true,
                    },
                    {
                        name: 'handoverPointAddress',
                        componentName: 'InputSingleline',
                        labelKey: 'AccountLane.handoverPointAddress',
                        disabled: true,
                    },
                    {
                        name: 'handoverPointCountry',
                        componentName: 'CountryDataSelector',
                        labelKey: 'AccountLane.handoverPointCountry',
                        required: true,
                        disabled: true,
                    },
                ] as NestedField<AccountLaneForm>[] : []),
                {
                    name: 'handoverPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'AccountLane.handoverPointContacts',
                    disabled: true,
                },
            ],
        },
    ]

    const collectionFields: FieldInCard<AccountLaneForm>[] = [
        {
            id: 'column1',
            fields: [{
                name: 'collectionServiceType',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(CollServiceType),
                labelKey: 'AccountLane.collectionServiceType',
                disabled: true,
            }],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'collectionDropoffPoint',
                    componentName: 'LocationExpandedViewObjectSelector',
                    labelKey: 'LaneGroup.collectionDropoffPoint',
                    required: true,
                    includeFilters: {
                        isProspect: [
                            true,
                            false,
                        ],
                    },
                },
                ...(isCollection ? [
                    {
                        name: 'collectionDropoffPointZipCode',
                        componentName: 'InputSingleline',
                        labelKey: 'AccountLane.collectionDropoffPointZipCode',
                        required: true,
                        disabled: true,
                    },
                    {
                        name: 'collectionDropoffPointAddress',
                        componentName: 'InputSingleline',
                        labelKey: 'AccountLane.collectionDropoffPointAddress',
                        disabled: true,
                    },
                    {
                        name: 'collectionDropoffPointCountry',
                        componentName: 'CountryDataSelector',
                        labelKey: 'AccountLane.collectionDropoffPointCountry',
                        required: true,
                        disabled: true,
                    },
                ] as NestedField<AccountLaneForm>[] : []),
                {
                    name: 'collectionDropoffPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'AccountLane.collectionDropoffPointContacts',
                    disabled: true,
                },
            ],
        },
    ]

    const consigneeFields: FieldInCard<AccountLaneForm>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'consignee',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'OrderByNumberView.consignee',
                },
                {
                    name: 'consigneeProvisional',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.consigneeProvisional',
                },
            ],
        },
        {
            id: 'column2',
            fields: [{
                name: 'consigneeContacts',
                componentName: 'MultipleContactObjectSelector',
                labelKey: 'AccountLane.consigneeContacts',
            }],
        },
    ]
    const customerExecutionFields: FieldInCard<AccountLaneForm>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'customerExecution',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'LaneOpsInformationDto.customerExecution',
                },
                {
                    name: 'customerExecutionProvisional',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.customerExecutionProvisional',
                },
            ],
        },
        {
            id: 'column2',
            fields: [{
                name: 'customerExecutionContacts',
                componentName: 'MultipleContactObjectSelector',
                labelKey: 'AccountLane.customerExecutionContacts',
            }],
        },
    ]

    return [
        {
            id: 'general',
            title: 'General',
            className: 'general',
            fields: generalFields,
        },
        {
            id: 'consignee',
            title: 'Consignee',
            className: 'consignee',
            fields: consigneeFields,
        },
        {
            id: 'customerExecution',
            title: 'Customer (Execution)',
            className: 'customerExecution',
            fields: customerExecutionFields,
        },
        {
            id: 'handover',
            title: 'Handover',
            className: 'handover',
            fields: handoverFields,
            getTitlePanelElements: getAddressCreationLink,
        },
        {
            id: 'collection',
            title: 'Collection',
            className: 'collection',
            fields: collectionFields,
            getTitlePanelElements: getAddressCreationLink,
        },
    ]
}

export default getFields
