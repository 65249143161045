import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'

import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    ProductType,
} from 'app/types/enums'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'
import {
    ModificationRequiredContainersType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import useModifyRequiredContainersCT from '../hooks/useModifyRequiredContainersCT'
import useStyles from './ModifyRequiredContainersCTDialog.style'
import getFields from './modifyRequiredContainersCTDialog.fields'

type Props = {
    open: boolean,
    version: number,
    setOpen: (value: boolean) => void
    requiredContainers: RequiredContainersCustomer[],
    orderNumber: string,
    requiredCargoComment: string,
    allowedProductTypes: ProductType[],
    onSuccess: () => void
}

const DIALOG_TITLE = 'REQUIRED CONTAINER MODIFICATION'

const ModifyRequiredContainersCTDialog = ({
    open,
    version,
    setOpen,
    onSuccess,
    orderNumber,
    requiredContainers,
    requiredCargoComment,
    allowedProductTypes,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const [
        value,
        setValue,
    ] = useState<Partial<ModificationRequiredContainersType>>({
        requiredContainers,
        requiredCargoComment,
    })

    const onSuccessModification = (): void => {
        setOpen(false)
        onSuccess()
    }

    const {
        isPending: isLoading,
        mutate,
    } = useModifyRequiredContainersCT(onSuccessModification)

    const modifyRequiredContainers = useCallback((): void => {
        mutate({
            modificationReason: value?.modificationReason,
            orderNumber,
            data: value,
            version,
        })
    }, [
        mutate,
        version,
        orderNumber,
        value,
    ])

    const handleClose = (action: boolean): void => {
        if (!action) {
            setOpen(false)
            setValue({
                requiredContainers,
                requiredCargoComment,
            })

            return
        }

        modifyRequiredContainers()
    }

    const fields = useMemo(() => {
        return getFields({
            allowedProductTypes,
            getAllowedValues,
            modificationReason: value?.modificationReason,
        })
    }, [
        allowedProductTypes,
        getAllowedValues,
        value?.modificationReason,
    ])

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            dialogTitle={DIALOG_TITLE}
            positiveLabel="modify"
            negativeLabel="cancel"
            isLoading={isLoading}
            classNames={{
                paper: classes.paper,
            }}
        >
            <DomainObjectForm
                fields={fields}
                name="ModifyRequiredContainers"
                value={value}
                onChange={setValue}
                className={classes.gridWrapper}
                wrapper="simple"
            />
        </ConfirmationDialog>
    )
}

export default ModifyRequiredContainersCTDialog
